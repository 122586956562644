import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import axios from 'axios';
import PropTypes from 'prop-types';
import { format, subDays, addDays } from 'date-fns';
import { pt } from 'date-fns/esm/locale';
import {
  Card,
  Divider,
  Button,
  Grid,
  makeStyles,
  FormControl,
  Typography,
  CardActionArea,
  CardMedia,
  Dialog,
  DialogContent,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { toAbsoluteUrl } from '../../../../_metronic';
import {
  dynamicArraySort,
  formatPrice,
  getStatus,
  getGrain,
  tableLocalization,
} from '../../../../_metronic/utils/utils';
import {
  defaultStaticRanges,
  defaultInputRanges,
} from '../../../../_metronic/utils/definedRanges';
import Status from '../../../components/Status';
import CustomField from '../../../components/CustomField';

const emptyOffersImage = toAbsoluteUrl(
  '/media/illustrations/empty_background.png'
);

const currency = {
  type: 'currency',
  currencySetting: {
    currencyCode: 'BRL',
    locale: 'pt',
    maximumFractionDigits: 3,
    minimumFractionDigits: 2,
  },
};

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    padding: 16,
  },
  divider: {
    marginTop: 16,
    marginBottom: 8,
  },
  media: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonFilter: {
    marginTop: 12,
    verticalAlign: 'initial',
  },
  divButtonDetails: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
    marginBottom: 16,
  },
  buttonDetails: {
    display: 'flex',
    justifyContent: 'center',
    width: 250,
    marginLeft: 8,
  },
  customIsDoneColor: {
    marginTop: 8,
    marginLeft: -8,
    backgroundColor: '#000000',
  },
  customIsNotDoneColor: {
    marginTop: 8,
    marginLeft: -8,
    backgroundColor: '#006837',
  },
  customBadgeIsCanceled: {
    marginTop: 8,
    marginLeft: -8,
    backgroundColor: '#cc0000',
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  dialogContent: {
    padding: 16,
  },
  title: {
    marginBottom: 16,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'center',
    height: '100%',
  },
  containerEmptyImage: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  emptyMedia: {
    width: '30%',
  },
  titleButtonContainer: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 8,
  },
}));

const OrderTable = ({ classes, transactions, isBuying }) => {
  transactions.map(value => {
    value.order.totalValue = formatPrice(
      value.order.amount * value.order.bagPrice
    );
    return value;
  });

  return (
    <div style={{ marginBottom: 16 }}>
      <MaterialTable
        className={classes.table}
        columns={[
          {
            field: 'order.createdBy.name',
            title: `${isBuying ? 'Vendido' : 'Comprado'} por`,
            width: null,
          },
          {
            field: 'order.address.name',
            title: 'Endereço',
          },
          {
            field: 'order.address.city.name',
            title: 'Cidade',
          },
          {
            field: 'order.address.city.uf',
            title: 'UF',
          },
          {
            field: 'order.amount',
            title: 'Quantidade',
            type: 'numeric',
          },
          {
            field: 'order.bagPrice',
            title: 'Preço da saca',
            type: 'currency',
            ...currency,
          },
          {
            field: 'order.totalValue',
            title: 'Valor total',
          },
          {
            field: 'order.createdAt',
            defaultSort: 'desc',
            title: 'Criado em',
            type: 'date',
          },
          {
            field: 'isDone',
            title: 'Finalizada?',
            type: 'boolean',
          },
          {
            field: 'isCanceled',
            title: 'Cancelada?',
            type: 'boolean',
          },
        ]}
        data={transactions}
        options={{
          padding: 'dense',
          paging: false,
          toolbar: false,
        }}
        localization={tableLocalization('pedido')}
      />
    </div>
  );
};

const ProcessedDataTable = ({ classes, processedData, isBuying }) => (
  <div style={{ marginBottom: 16 }}>
    <MaterialTable
      className={classes.table}
      columns={[
        {
          field: isBuying ? 'distance.fromUserName' : 'distance.toUserName',
          title: `Potencial ${isBuying ? 'Produtor' : 'Comprador'}`,
          width: null,
        },
        {
          field: `distance[${isBuying ? 'from' : 'to'}].name`,
          title: isBuying ? 'Origem' : 'Destino',
        },
        {
          field: `distance[${isBuying ? 'from' : 'to'}].city.name`,
          title: 'Cidade',
        },
        {
          field: `distance[${isBuying ? 'from' : 'to'}].city.uf`,
          title: 'UF',
        },
        {
          field: 'bagPrice',
          title: 'Preço da saca',
          type: 'currency',
          ...currency,
        },
        {
          field: 'distance.inKm',
          title: 'Distância asfaltada (km)',
        },
        {
          field: 'dirtRoadKm',
          title: 'Estrada de chão (km)',
        },
        {
          field: 'minFreightPrice',
          title: 'Valor mínimo do frete',
          type: 'currency',
          ...currency,
        },
        {
          field: 'freightPricePerKm',
          title: 'Valor do km',
          type: 'currency',
          ...currency,
        },
        {
          field: 'dirtRoadKmPrice',
          title: 'Valor do km (chão)',
          type: 'currency',
          ...currency,
        },
        {
          field: 'freightPrice',
          title: 'Frete por saca',
          type: 'currency',
          ...currency,
        },
        {
          field: 'dirtRoadFreightPrice',
          title: 'Frete por saca (chão)',
          type: 'currency',
          ...currency,
        },
        {
          field: 'fullFreightPrice',
          title: 'Frete total',
          type: 'currency',
          ...currency,
        },
        {
          field: 'foxFee',
          title: 'Taxa da FOX',
          type: 'currency',
          ...currency,
        },
        {
          field: 'taxes',
          title: 'Impostos',
          type: 'numeric',
        },
        {
          field: 'payableUntil',
          title: 'Pagável até',
          type: 'date',
        },
      ]}
      data={processedData}
      options={{
        columnsButton: true,
        padding: 'dense',
        paging: false,
        toolbarButtonAlignment: 'left',
      }}
      localization={tableLocalization('dado')}
      title=""
    />
  </div>
);

const DetailsDialog = ({
  openDetailsDialog,
  classes,
  handleDetailsDialogClose,
  offersDetails,
}) => {
  const [openProcessedDataTable, setOpenProcessedDataTable] = useState(false);
  const [openOrdersTable, setOpenOrdersTable] = useState(false);
  const { processedData, transactions } = offersDetails;

  const offerFields = useMemo(() => {
    if (offersDetails && offersDetails.createdAt) {
      return {
        type: offersDetails.isBuying ? 'Compra' : 'Venda',
        address: `${offersDetails.address.city.name} - ${offersDetails.address.city.uf}`,
        bagPrice: String(formatPrice(offersDetails.bagPrice)),
        amount: String(`${offersDetails.amount} sacas`),
        totalPrice: String(
          formatPrice(offersDetails.amount * offersDetails.bagPrice)
        ),
        amountOrdered: String(`${offersDetails.amountOrdered} sacas`),
        totalOrdered: String(
          formatPrice(offersDetails.amountOrdered * offersDetails.bagPrice)
        ),
        createdAt: format(
          new Date(String(offersDetails.createdAt)),
          'dd/MM/yyyy'
        ),
        createdBy: offersDetails.createdBy.name,
        expiresIn: format(
          new Date(String(offersDetails.expiresIn)),
          'dd/MM/yyyy'
        ),
        grain: getGrain(offersDetails.grain).grain,
        status: getStatus(offersDetails.isDone, offersDetails.isCanceled),
      };
    }
    return {};
  }, [offersDetails]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={openDetailsDialog}
      onClose={() => {
        setOpenProcessedDataTable(false);
        setOpenOrdersTable(false);
        handleDetailsDialogClose();
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <>
          <Grid container spacing={2} justify="space-between">
            <Grid item>
              <Typography component="h3" variant="h5">
                Detalhes da Oferta
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => handleDetailsDialogClose()}
                color="primary"
              >
                Fechar
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <CustomField field="Tipo" value={offerFields.type} xs={12} sm={6} />
            <CustomField
              field="Grão"
              value={offerFields.grain}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Cidade"
              value={offerFields.address}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Preço/Saca"
              value={offerFields.bagPrice}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Qtd. Ofertada"
              value={offerFields.amount}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Valor Total"
              value={offerFields.totalPrice}
              xs={12}
              sm={6}
            />
          </Grid>
          <Grid container spacing={2}>
            <CustomField
              field="Qtd. Negociada"
              value={offerFields.amountOrdered}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Total Negociado"
              value={offerFields.totalOrdered}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Criada em"
              value={offerFields.createdAt}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Criada por"
              value={offerFields.createdBy}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Expira em"
              value={offerFields.expiresIn}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Situação"
              value={offerFields.status}
              xs={12}
              sm={6}
            />
          </Grid>
          <Divider className={classes.divider} />
          <div className={classes.titleButtonContainer}>
            <Typography component="h3" variant="h5">
              Dados processados desta oferta
            </Typography>
            <Button
              onClick={() => setOpenProcessedDataTable(!openProcessedDataTable)}
              color="primary"
            >
              {!openProcessedDataTable ? 'Ver detalhes' : 'Ocultar detalhes'}
            </Button>
          </div>
          {openProcessedDataTable && (
            <ProcessedDataTable
              classes={classes}
              processedData={processedData}
              isBuying={offersDetails.isBuying}
            />
          )}
          <Divider className={classes.divider} />

          <div className={classes.titleButtonContainer}>
            <Typography component="h3" variant="h5">
              Pedidos desta oferta
            </Typography>
            <Button
              onClick={() => setOpenOrdersTable(!openOrdersTable)}
              color="primary"
            >
              {!openOrdersTable ? 'Ver detalhes' : 'Ocultar detalhes'}
            </Button>
          </div>
          {openOrdersTable && (
            <OrderTable
              openOrdersTable={openOrdersTable}
              classes={classes}
              transactions={transactions}
              isBuying={offersDetails.isBuying}
            />
          )}
        </>
      </DialogContent>
    </Dialog>
  );
};

export default function OffersPage() {
  const loggedUser = useSelector(state => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [users, setUsers] = useState([]);
  const [offersDetails, setOffersDetails] = useState({});
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [filters, setFilters] = useState({
    grain: '',
    isBuying: '',
    isCanceled: false,
    isDone: false,
    createdBy: '',
    startDate: '',
    endDate: '',
  });
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: addDays(new Date(), 30),
      key: 'selection',
    },
  ]);
  const [offerStatus, setOfferStatus] = useState('isOpen');
  const [ascendingOrder, setAscendingOrder] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [params, setParams] = useState({
    filter: true,
    isDone: false,
    isCanceled: false,
  });
  const [filterByPeriod, setFilterByPeriod] = useState(false);

  useEffect(() => {
    const loadOffers = async () => {
      setIsLoading(true);

      try {
        const {
          data: res,
        } = await axios.get(`/offers/consultant/${loggedUser._id}`, { params });

        if (res && res.data) {
          setOffers(res.data);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      } finally {
        setIsLoading(false);
      }
    };

    loadOffers();
  }, [loggedUser, params]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const { data: res } = await axios.get('/users');
        if (res && res.data) {
          setUsers(
            res.data.filter(user => {
              return user.isBuyer || user.isSeller;
            })
          );
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };

    loadUsers();
  }, []);

  const handleFilterChange = (label, value) => {
    if (label === 'dateFilter') {
      setFilters({
        ...filters,
        startDate: value.startDate,
        endDate: value.endDate,
      });
    } else {
      setFilters({ ...filters, [label]: value });
    }
  };

  const handleDetailsDialogClose = () => {
    setOpenDetailsDialog(false);
    setOffersDetails({});
  };

  const handleFilter = () => {
    const filter = !(
      filters.isDone === '' &&
      filters.grain === '' &&
      filters.isCanceled === '' &&
      filters.isBuying === '' &&
      filters.createdBy === '' &&
      filters.startDate === '' &&
      filters.endDate === ''
    );
    setParams({ ...filters, filter });
  };

  const getOfferDetails = offerId => {
    const loadOfferDetails = async () => {
      const { data: res } = await axios.get(`/offers/${offerId}?fields=all`);

      if (res && res.data) setOffersDetails(res.data);
    };

    loadOfferDetails();
    setOpenDetailsDialog(true);
  };

  const classes = useStyles();

  const handleOnStatusChange = value => {
    setOfferStatus(value);
    if (value === 'isCanceled') {
      setFilters({ ...filters, isCanceled: true, isDone: '' });
    } else if (value === 'isDone' || value === 'isOpen') {
      setFilters({
        ...filters,
        isCanceled: false,
        isDone: value === 'isDone',
      });
    } else {
      setFilters({ ...filters, isCanceled: '', isDone: '' });
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel>Situação</InputLabel>
            <Select
              value={offerStatus}
              onChange={(_, e) => handleOnStatusChange(e.props.value)}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value="isCanceled">Canceladas</MenuItem>
              <MenuItem value="isOpen">Abertas</MenuItem>
              <MenuItem value="isDone">Finalizadas</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Tipo de oferta</InputLabel>
            <Select
              value={filters.isBuying}
              onChange={(_, e) => handleFilterChange('isBuying', e.props.value)}
            >
              <MenuItem value="">
                <em>Todas</em>
              </MenuItem>
              <MenuItem value>Compra</MenuItem>
              <MenuItem value={false}>Venda</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Grão</InputLabel>
            <Select
              value={filters.grain}
              onChange={(_, e) => handleFilterChange('grain', e.props.value)}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value={1}>Milho</MenuItem>
              <MenuItem value={2}>Soja</MenuItem>
              <MenuItem value={3}>Sorgo</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Criado por</InputLabel>
            <Select
              value={filters.createdBy}
              onChange={(_, e) =>
                handleFilterChange('createdBy', e.props.value)
              }
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {users.map(user => (
                <MenuItem key={user._id} value={user._id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              onClick={() => setFilterByPeriod(!filterByPeriod)}
              color="primary"
              variant="outlined"
              className={classes.buttonFilter}
            >
              {filterByPeriod
                ? 'Fechar filtro por período'
                : 'Filtrar por período'}
            </Button>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              onClick={handleFilter}
              color="primary"
              variant="outlined"
              className={classes.buttonFilter}
            >
              Filtrar
            </Button>
          </FormControl>
          {filterByPeriod && (
            <>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <DateRangePicker
                  onChange={item => {
                    setDateFilter([item.selection]);
                    handleFilterChange('dateFilter', item.selection);
                  }}
                  showSelectionPreview
                  months={1}
                  ranges={dateFilter}
                  direction="horizontal"
                  locale={pt}
                  color="#006837"
                  rangeColors={['#006837']}
                  dateDisplayFormat="dd/MM/yyyy"
                  staticRanges={defaultStaticRanges}
                  inputRanges={defaultInputRanges}
                  weekStartsOn={0}
                />
              </Grid>
            </>
          )}
        </div>
        <FormControl className={classes.formControl}>
          <InputLabel>Ordenar por</InputLabel>
          <Select
            value={sortBy}
            onChange={(_, e) => {
              setOffers(offers.sort(dynamicArraySort(e.props.value)));
              setSortBy(e.props.value);
            }}
          >
            <MenuItem value="createdAt">Data de criação</MenuItem>
            <MenuItem value="bagPrice">Valor da saca</MenuItem>
            <MenuItem value="amount">Quantidade de sacas</MenuItem>
            <MenuItem value="isBuying">Tipo de oferta</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} disabled={!sortBy}>
          <InputLabel>Em ordem</InputLabel>
          <Select
            value={ascendingOrder}
            onChange={(_, e) => {
              setAscendingOrder(e.props.value);
              setOffers(
                offers.sort(
                  dynamicArraySort(
                    e.props.value === true ? sortBy : `-${sortBy}`
                  )
                )
              );
            }}
          >
            <MenuItem value>Crescente</MenuItem>
            <MenuItem value={false}>Decrescente</MenuItem>
          </Select>
        </FormControl>
      </Card>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {!offers.length ? (
            <div className={classes.containerEmptyImage}>
              <CardMedia
                className={classes.emptyMedia}
                component="img"
                image={emptyOffersImage}
              />
              <Typography gutterBottom variant="h6" component="h2">
                Nenhuma oferta encontrada.
              </Typography>
            </div>
          ) : (
            <Grid container spacing={2}>
              {offers.map(offer => {
                const { grain, image } = getGrain(offer.grain);

                return (
                  <Grid
                    key={offer._id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    xl={3}
                    zeroMinWidth
                  >
                    <Card onClick={() => getOfferDetails(offer._id)}>
                      <CardActionArea
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          padding: 16,
                        }}
                      >
                        <CardMedia
                          component="img"
                          className={classes.media}
                          image={image}
                        />
                        <div
                          style={{ flex: 1, marginLeft: 8, maxWidth: '80%' }}
                        >
                          <Typography gutterBottom variant="h5" component="h2">
                            {offer.isBuying ? 'Compra' : 'Venda'} de {grain}
                          </Typography>
                          <Typography noWrap>
                            Criada por {offer.createdBy.name}
                          </Typography>
                          <Typography noWrap>
                            {`${offer.amountOrdered} de ${offer.amount} sacas ${
                              offer.isBuying ? 'compradas' : 'vendidas'
                            }`}
                          </Typography>
                          <Typography noWrap>
                            {formatPrice(offer.bagPrice)} por saca
                          </Typography>
                          <Typography noWrap>
                            {`${offer.address.name}, ${offer.address.city.name} - ${offer.address.city.uf}`}
                          </Typography>
                          <Status
                            isDone={offer.isDone}
                            isCanceled={offer.isCanceled}
                          />
                        </div>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </>
      )}
      <DetailsDialog
        openDetailsDialog={openDetailsDialog}
        classes={classes}
        handleDetailsDialogClose={handleDetailsDialogClose}
        offersDetails={offersDetails}
      />
    </div>
  );
}

DetailsDialog.propTypes = {
  openDetailsDialog: PropTypes.bool,
  classes: PropTypes.oneOfType([PropTypes.object]),
  handleDetailsDialogClose: PropTypes.func,
  offersDetails: PropTypes.oneOfType([PropTypes.object]),
};

DetailsDialog.defaultProps = {
  openDetailsDialog: false,
  classes: {},
  handleDetailsDialogClose: null,
  offersDetails: {},
};

ProcessedDataTable.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]),
  processedData: PropTypes.oneOfType([PropTypes.array]),
  isBuying: PropTypes.bool,
};

ProcessedDataTable.defaultProps = {
  classes: {},
  processedData: [],
  isBuying: false,
};

OrderTable.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]),
  transactions: PropTypes.oneOfType([PropTypes.array]),
  isBuying: PropTypes.bool,
};

OrderTable.defaultProps = {
  classes: {},
  transactions: [],
  isBuying: false,
};
