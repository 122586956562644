import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { format } from 'date-fns';
import { Grid, Paper, Typography } from '@material-ui/core';
import ContractDetails from '../../../components/ContractDetails';
import {
  formatPrice,
  tableLocalization,
} from '../../../../_metronic/utils/utils';
import CustomField from '../../../components/CustomField';

const OfferTable = ({ offerId, isBuyingOffer }) => {
  const [offer, setOffer] = useState({});

  useEffect(() => {
    const loadOffer = async () => {
      try {
        const { data: res } = await axios.get(`/offers/${offerId}?fields=all`);
        if (res && res.data) {
          setOffer(res.data);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };

    loadOffer();
  }, [offerId]);

  const offerFields = useMemo(() => {
    if (offer && offer.createdAt) {
      return {
        createdAt: String(
          format(new Date(String(offer.createdAt)), 'dd/MM/yyyy')
        ),
        bagPrice: String(formatPrice(offer.bagPrice)),
        offerValue: String(formatPrice(offer.amount * offer.bagPrice)),
        totalOrdered: String(formatPrice(offer.amountOrdered * offer.bagPrice)),
        expiresIn: String(
          format(new Date(String(offer.expiresIn)), 'dd/MM/yyyy')
        ),
        createdBy: offer.createdBy.name,
        amount: `${offer.amount} sacas`,
        amountOrdered: `${offer.amountOrdered} sacas`,
        address: `${offer.address.name}, ${offer.address.city.name} - ${offer.address.city.uf}`,
      };
    }
    return {};
  }, [offer]);

  return (
    <>
      {offer && (
        <>
          <Grid container spacing={2}>
            <CustomField
              field={isBuyingOffer ? 'Comprada por' : 'Vendida por'}
              value={offerFields.createdBy}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Criada em"
              value={offerFields.createdAt}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Quantidade ofertada"
              value={offerFields.amount}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Preço da saca"
              value={offerFields.bagPrice}
              xs={12}
              sm={6}
            />
          </Grid>
          <Grid container spacing={2}>
            <CustomField
              field="Valor da oferta"
              value={offerFields.offerValue}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Quantidade negociada"
              value={offerFields.amountOrdered}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Total negociado"
              value={offerFields.totalOrdered}
              xs={12}
              sm={6}
            />
            <CustomField
              field="Expira em"
              value={offerFields.expiresIn}
              xs={12}
              sm={6}
            />
          </Grid>
          <Grid container spacing={2}>
            <CustomField
              field="Endereço"
              value={offerFields.address}
              xs={12}
              sm={6}
            />
          </Grid>
        </>
      )}
    </>
  );
};

const TicketTable = ({ ticket, contractId }) => {
  const history = useHistory();

  return (
    <div style={{ marginBottom: 16 }}>
      <MaterialTable
        columns={[
          {
            field: 'ticket',
            title: 'Ticket',
            width: null,
          },
          {
            field: 'status',
            title: 'Situação',
          },
          {
            field: 'truck.type.scaleWeight',
            type: 'numeric',
            title: 'Peso tara (kg)',
            emptyValue: '-',
          },
          {
            field: 'grossWeight',
            type: 'numeric',
            title: 'Peso bruto (kg)',
            emptyValue: '-',
          },
          {
            field: 'amount',
            type: 'numeric',
            render: ({ amount }) => Math.round(amount),
            title: 'Quantidade de sacas',
            emptyValue: '-',
          },
          {
            field: 'loadingDate',
            title: 'Data de carregamento',
            type: 'datetime',
          },
          {
            field: 'deliveredIn',
            title: 'Data da entrega',
            type: 'datetime',
            emptyValue: '-',
          },
          {
            field: 'truck.plate',
            title: 'Placa do caminhão',
            emptyValue: '-',
          },
          {
            field: 'truck.type.capacities[1]',
            type: 'numeric',
            title: 'Capacidade do caminhão',
            emptyValue: '-',
          },
          {
            field: 'responsible.name',
            title: 'Responsável',
          },
          {
            field: 'truck.driver',
            title: 'Motorista',
            emptyValue: '-',
          },
          {
            field: 'createdAt',
            title: 'Criado em',
            type: 'datetime',
          },
        ]}
        data={ticket}
        options={{
          padding: 'dense',
          paging: false,
          toolbar: false,
        }}
        localization={tableLocalization('ticket')}
        actions={[
          {
            icon: 'create',
            tooltip: 'Editar ticket',
            onClick: (_, rowData) =>
              history.push('/ticket-form', {
                editTicket: rowData,
                contractId,
              }),
          },
        ]}
      />
    </div>
  );
};

export default function ContractsDetailsPage({ location }) {
  const [state, setState] = useState({});

  useEffect(() => {
    const loadPage = async () => {
      setState(location.state);
    };
    loadPage();
  }, [location]);

  return (
    <>
      <Paper style={{ padding: 24, marginBottom: 24 }}>
        <Typography component="h3" variant="h5" style={{ marginBottom: 8 }}>
          Detalhes do contrato
        </Typography>
        <ContractDetails contractId={state.transactionsId} />
      </Paper>
      <Paper style={{ padding: 24, marginBottom: 24 }}>
        <Typography component="h3" variant="h5" style={{ marginBottom: 8 }}>
          Oferta deste contrato
        </Typography>
        <OfferTable
          offerId={state.offerId}
          isBuyingOffer={state.isBuyingOffer}
        />
      </Paper>
      <Paper style={{ padding: 24, marginBottom: 24 }}>
        <Typography component="h3" variant="h5" style={{ marginBottom: 8 }}>
          Tickets deste contrato
        </Typography>
        <TicketTable ticket={state.tickets} contractId={state.transactionsId} />
      </Paper>
    </>
  );
}

ContractsDetailsPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
};

ContractsDetailsPage.defaultProps = {
  location: {},
};

OfferTable.propTypes = {
  offerId: PropTypes.string,
  isBuyingOffer: PropTypes.bool,
};

OfferTable.defaultProps = {
  offerId: '',
  isBuyingOffer: false,
};

TicketTable.propTypes = {
  ticket: PropTypes.oneOfType([PropTypes.array]),
  contractId: PropTypes.string,
};

TicketTable.defaultProps = {
  ticket: [],
  contractId: '',
};
