import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import axios from 'axios';
import { Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ChipInput from 'material-ui-chip-input';
import { DropzoneArea } from 'material-ui-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';

import { DecimalInput } from '../../../components/CustomInputs';
import SnackbarContentWrapper from '../../../components/SnackbarContentWrapper';
import ie from '../../../../_metronic/utils/ie.min';
import {
  cnpjMask,
  cpfMask,
  isObject,
  localeDecimalNumber,
  phoneMask,
  tableLocalization,
  validateCpf,
  validateCnpj,
} from '../../../../_metronic/utils/utils';

Yup.setLocale({
  mixed: {
    default: 'Valor inválido.',
    required: 'Campo obrigatório.',
    notType: 'Tipo de valor inválido.',
  },
  number: {
    max: 'Deve ser no máximo ${max}.', // eslint-disable-line no-template-curly-in-string
    min: 'Deve ser no mínimo ${min}.', // eslint-disable-line no-template-curly-in-string
    positive: 'Deve ser um número positivo.',
  },
});

const addInputAdornment = (adornment, position = 'end') => ({
  [`${position}Adornment`]: (
    <InputAdornment position={position}>{adornment}</InputAdornment>
  ),
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  formControl: {
    marginBottom: 0,
    marginTop: theme.spacing(2),
  },
  helperText: {
    marginTop: 0,
  },
  textField: {
    marginTop: 0,
  },

  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },

  addressSectionHeader: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  addressSectionHeaderItem: {
    marginBottom: theme.spacing(1),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },

  filesSectionHeader: {
    margin: theme.spacing(2, 0),
  },
  filesSectionBody: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  filesSectionAvatar: {
    marginRight: theme.spacing(1),
  },
  filesSectionAvatarContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  filesSectionAvatarPreview: {
    borderRadius: '50%',
    maxHeight: 120,
    maxWidth: 120,
  },
  filesSectionDocuments: {
    display: 'flex',
    minWidth: 300,
  },
  filesSectionDocumentsDropzone: {
    minHeight: 120,
  },
  filesSectionDocumentsDropzoneText: {
    marginTop: 8,
  },
  filesSectionDocumentsFile: {
    marginTop: 4,
  },
  filesSectionDocumentsDeleteFile: {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.error.main,
    marginLeft: 4,
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },

  bottomButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  bottomButton: {
    margin: theme.spacing(0, 1),
  },
  addressForm: {
    marginTop: theme.spacing(2),
  },
  selectFormControl: {
    display: 'flex',
  },
  dialogDivider: {
    margin: theme.spacing(2, 0),
  },
  dialogActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

const AddressForm = ({
  address,
  addressList,
  classes,
  handleAddressFormClose,
  isAddressFormOpen,
  cities,
  setAddressList,
  isUserSeller,
  isUserBuyer,
}) => {
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = isAutocompleteOpen && options.length === 0;

  useEffect(() => {
    if (!isUserSeller || !isUserBuyer) {
      if (isUserBuyer) {
        address.isBuyAddress = 'true';
      } else {
        address.isBuyAddress = 'false';
      }
    } else {
      address.isBuyAddress = '';
    }
  });

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (() => {
      if (active) {
        setOptions(cities);
      }
    })();

    return () => {
      active = false;
    };
  }, [cities, loading]);

  useEffect(() => {
    if (!isAutocompleteOpen) setOptions([]);
  }, [isAutocompleteOpen]);

  return (
    <Dialog
      aria-labelledby="Cadastrar endereço"
      aria-describedby="Formulário para cadastro de endereço"
      fullWidth
      maxWidth="md"
      onClose={handleAddressFormClose}
      open={isAddressFormOpen}
    >
      <DialogContent>
        <Typography component="h3" variant="h5">
          {address.tableData ? 'Atualizar' : 'Adicionar'} Endereço
        </Typography>
        <FormHelperText>Campos com * são obrigatórios.</FormHelperText>
        <Formik
          initialValues={address}
          onSubmit={values => {
            const newAddress = {
              ...values,
              location: {
                coordinates: [values.longitude, values.latitude],
              },
            };

            let newAddressList;
            if (address.tableData) {
              newAddressList = addressList.map(a =>
                a.tableData.id === address.tableData.id ? newAddress : a
              );
            } else {
              newAddressList = [...addressList, newAddress];
            }

            setAddressList(newAddressList);
            handleAddressFormClose();
          }}
          validationSchema={Yup.object({
            city: Yup.string().required(),
            dirtRoadKm: Yup.number()
              .min(0)
              .required(),
            isTaxPaidOnRevenue: Yup.boolean().when(
              'isBuyAddress',
              (isBuyAddress, field) =>
                isBuyAddress === false ? field.required() : field.notRequired()
            ),
            latitude: Yup.number()
              .min(-90)
              .max(90)
              .required(),
            longitude: Yup.number()
              .min(-180)
              .max(180)
              .required(),
            mapsLink: Yup.string().required(),
            name: Yup.string().required(),
            productionAreaInHa: Yup.number()
              .positive()
              .when('isBuyAddress', (isBuyAddress, field) =>
                isBuyAddress === false ? field.required() : field.notRequired()
              ),
            addressRoute: Yup.string(),
            addressNote: Yup.string(),
            isBuyAddress: Yup.boolean(),
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            touched,
            values,
          }) => {
            const handleNewAddress = () => {
              const fields = Object.keys(values);
              const isFormValid = !Object.keys(errors).length;

              fields.forEach(f => setFieldTouched(f, true));

              if (isFormValid) handleSubmit();
            };
            const inputComponent = DecimalInput;

            return (
              <Form className={classes.addressForm}>
                {isUserBuyer && isUserSeller && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl
                        className={classes.formControl}
                        component="fieldset"
                        error={touched.isBuyAddress && !!errors.isBuyAddress}
                        required
                      >
                        <FormLabel component="legend">
                          Selecione o tipo de endereço
                        </FormLabel>
                        <RadioGroup
                          name="isBuyAddress"
                          onChange={handleChange}
                          row
                          value={values.isBuyAddress}
                        >
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Compra"
                            value="true"
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Venda"
                            value="false"
                          />
                        </RadioGroup>
                        {touched.isBuyAddress && !!errors.isBuyAddress && (
                          <FormHelperText className={classes.helperText}>
                            {errors.isBuyAddress}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2}>
                  <Grid item md xs={12}>
                    <TextField
                      className={classes.textField}
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      label="Nome do Local"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.name}
                    />
                  </Grid>
                  <Grid item md xs={12}>
                    <Autocomplete
                      autoHighlight
                      blurOnSelect="touch"
                      filterOptions={createFilterOptions({
                        limit: 10,
                        matchFrom: 'start',
                        stringify: city => `${city.name} - ${city.uf}`,
                      })}
                      getOptionLabel={({ name, uf }) =>
                        name ? `${name} - ${uf}` : ''
                      }
                      getOptionSelected={(city, value) =>
                        city._id === value._id
                      }
                      id="city"
                      loading={loading}
                      onBlur={handleBlur}
                      onChange={(_, value) => {
                        setFieldValue('city', value ? value._id : '');
                      }}
                      onClose={() => setIsAutocompleteOpen(false)}
                      onOpen={() => setIsAutocompleteOpen(true)}
                      open={isAutocompleteOpen}
                      options={options}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={touched.city && !!errors.city}
                          helperText={touched.city && errors.city}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          label="Cidade *"
                          value={values.city}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md xs={12}>
                    <TextField
                      className={classes.textField}
                      error={touched.dirtRoadKm && !!errors.dirtRoadKm}
                      helperText={touched.dirtRoadKm && errors.dirtRoadKm}
                      InputProps={{
                        ...addInputAdornment('km'),
                        inputComponent,
                      }}
                      label="Dist. em estrada de chão"
                      margin="normal"
                      name="dirtRoadKm"
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('dirtRoadKm', Number(e.target.value));
                      }}
                      required
                      value={values.dirtRoadKm}
                    />
                  </Grid>
                  {values.isBuyAddress === 'false' && (
                    <Grid item md xs={12}>
                      <TextField
                        className={classes.textField}
                        error={
                          touched.productionAreaInHa &&
                          !!errors.productionAreaInHa
                        }
                        helperText={
                          touched.productionAreaInHa &&
                          errors.productionAreaInHa
                        }
                        InputProps={{
                          ...addInputAdornment('hectares'),
                          inputComponent,
                        }}
                        label="Área de produção"
                        margin="normal"
                        name="productionAreaInHa"
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue(
                            'productionAreaInHa',
                            Number(e.target.value)
                          );
                        }}
                        required={values.isBuyAddress === 'false'}
                        value={values.productionAreaInHa}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={touched.mapsLink && !!errors.mapsLink}
                      helperText={touched.mapsLink && errors.mapsLink}
                      label="Link do maps"
                      margin="normal"
                      name="mapsLink"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.mapsLink}
                    />
                  </Grid>
                  <Grid item md xs={12}>
                    <TextField
                      className={classes.textField}
                      error={touched.latitude && !!errors.latitude}
                      helperText={touched.latitude && errors.latitude}
                      label="Latitude"
                      margin="normal"
                      name="latitude"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      type="number"
                      value={values.latitude}
                    />
                  </Grid>
                  <Grid item md xs={12}>
                    <TextField
                      className={classes.textField}
                      error={touched.longitude && !!errors.longitude}
                      helperText={touched.longitude && errors.longitude}
                      label="Longitude"
                      margin="normal"
                      name="longitude"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      type="number"
                      value={values.longitude}
                    />
                  </Grid>
                </Grid>
                {values.isBuyAddress === 'false' && (
                  <Grid container spacing={2}>
                    <Grid item md xs={12}>
                      <FormControl
                        component="fieldset"
                        error={
                          touched.isTaxPaidOnRevenue &&
                          !!errors.isTaxPaidOnRevenue
                        }
                        required={values.isBuyAddress === 'false'}
                      >
                        <FormLabel component="legend">
                          Selecione o tipo de recolhimento do Funrural
                        </FormLabel>
                        <RadioGroup
                          name="isTaxPaidOnRevenue"
                          onChange={handleChange}
                          row
                          value={values.isTaxPaidOnRevenue}
                        >
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Folha de pagamento"
                            value="false"
                          />
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            label="Receita de comercialização"
                            value="true"
                          />
                        </RadioGroup>
                        {touched.isTaxPaidOnRevenue &&
                          !!errors.isTaxPaidOnRevenue && (
                            <FormHelperText className={classes.helperText}>
                              {errors.isTaxPaidOnRevenue}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      error={touched.addressRoute && !!errors.addressRoute}
                      helperText={touched.addressRoute && errors.addressRoute}
                      label="Roteiro até o endereço"
                      margin="normal"
                      name="addressRoute"
                      multiline
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.addressRoute}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      error={touched.addressNote && !!errors.addressNote}
                      helperText={touched.addressNote && errors.addressNote}
                      label="Observações gerais"
                      margin="normal"
                      name="addressNote"
                      multiline
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.addressNote}
                    />
                  </Grid>
                </Grid>

                <Divider className={classes.dialogDivider} />

                <div className={classes.dialogActionButtons}>
                  <Button
                    className={classes.bottomButton}
                    color="secondary"
                    onClick={handleAddressFormClose}
                    type="button"
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.bottomButton}
                    color="primary"
                    onClick={handleNewAddress}
                    type="button"
                    variant="contained"
                  >
                    {address.tableData ? 'Atualizar' : 'Adicionar'}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const AddressTable = ({
  addressList,
  handleDeleteAddress,
  // handleEditAddress,
  cities,
  isUserSeller,
}) => (
  <MaterialTable
    actions={[
      // {
      //   icon: 'edit',
      //   tooltip: 'Editar',
      //   onClick: (_, rowData) => handleEditAddress(rowData),
      // },
      {
        icon: 'delete',
        tooltip: 'Remover',
        onClick: (_, rowData) => handleDeleteAddress(rowData),
      },
    ]}
    columns={[
      {
        field: 'isBuyAddress ',
        title: 'Tipo de endereço',
        render: ({ isBuyAddress }) =>
          isBuyAddress === 'true' ? 'Compra' : 'Venda',
        width: null,
      },
      { field: 'name', title: 'Nome' },
      {
        title: 'Cidade',
        render: ({ city }) => {
          const { name, uf } = isObject(city)
            ? city
            : cities.find(c => c._id === city);
          return `${name} - ${uf}`;
        },
      },
      {
        emptyValue: '-',
        field: 'productionAreaInHa',
        hidden: !isUserSeller,
        title: 'Área de prod.',
        render: ({ productionAreaInHa }) =>
          productionAreaInHa &&
          `${localeDecimalNumber(productionAreaInHa)} hectares`,
      },
      {
        field: 'dirtRoadKm',
        title: 'Estrada de chão',
        render: ({ dirtRoadKm }) => `${localeDecimalNumber(dirtRoadKm)} km`,
      },
      {
        emptyValue: '-',
        field: 'isTaxPaidOnRevenue',
        hidden: !isUserSeller,
        title: 'Funrural',
        render: ({ isTaxPaidOnRevenue }) =>
          isTaxPaidOnRevenue === 'true'
            ? 'Receita de comercialização'
            : 'Folha de pagamento',
      },
      {
        field: 'mapsLink',
        title: 'Link do Maps',
        render: (
          { mapsLink } // eslint-disable-line react/prop-types
        ) => (
          <a href={mapsLink} target="_blank" rel="noopener noreferrer">
            Acessar
          </a>
        ),
      },
      {
        field: 'location',
        title: 'Localização',
        render: ({ latitude, longitude }) => `${latitude}, ${longitude}`,
      },
      {
        field: 'addressRoute',
        title: 'Roteiro',
      },
      {
        field: 'addressNote',
        title: 'Observações gerais',
      },
    ]}
    data={addressList}
    localization={tableLocalization('endereço')}
    options={{
      padding: 'dense',
      paging: false,
      toolbar: false,
      showTitle: false,
    }}
  />
);

const emptyAddress = {
  city: '',
  dirtRoadKm: '',
  isTaxPaidOnRevenue: '',
  latitude: '',
  longitude: '',
  mapsLink: '',
  name: '',
  productionAreaInHa: '',
  addressRoute: '',
  addressNote: '',
  isBuyAddress: '',
};

const EditUserPage = ({ location }) => {
  const classes = useStyles();
  const history = useHistory();

  const loggedUser = useSelector(state => state.auth.user);

  const [address, setAddress] = useState(emptyAddress);
  const [addressList, setAddressList] = useState([]);
  const [addressValid, setAddressValid] = useState(false);
  const [cities, setCities] = useState([]);
  const [consultantName, setConsultantName] = useState(loggedUser.name);
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);
  const [isUserSeller, setIsUserSeller] = useState(false);
  const [isUserBuyer, setIsUserBuyer] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [newAvatar, setNewAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [newDocuments, setNewDocuments] = useState([]);
  const [profileChecked, setProfileChecked] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    variant: 'success',
  });

  const handleDeletestateRegistrations = (
    chipToDelete,
    values,
    setFieldValue
  ) => {
    const newArraystateRegistrations = values.stateRegistrations.filter(
      stateRegistrations => stateRegistrations !== chipToDelete
    );
    setFieldValue('stateRegistrations', newArraystateRegistrations);
  };

  const handleSnackbar = (
    message = '',
    variant = snackbar.variant || 'success'
  ) => {
    setSnackbar({
      isOpen: !!message,
      message,
      variant,
    });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSnackbar();
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    setValues,
    touched,
    values,
  } = useFormik({
    initialValues: {
      addressList: [],
      avatar: '',
      changePassword: true,
      consultant: loggedUser,
      cnpj: '',
      cpf: '',
      documents: [],
      email: '',
      isAdmin: false,
      isApproved: false,
      isBuyer: false,
      isConsultant: false,
      isLegalPerson: '',
      isLogistic: false,
      isSeller: false,
      harvestNote: '',
      hasScale: '',
      name: '',
      paymentCreditInDays: '',
      phone: '',
      stateRegistrations: [],
    },

    validationSchema: Yup.object({
      cnpj: Yup.string()
        .transform(cnpj => cnpj.replace(/\D/g, ''))
        .when('isLegalPerson', (isLegalPerson, field) =>
          isLegalPerson === 'true'
            ? field
                .required()
                .test('is-cnpj-valid', 'CNPJ inválido.', validateCnpj)
            : field.notRequired()
        ),
      cpf: Yup.string()
        .transform(cpf => cpf.replace(/\D/g, ''))
        .when('isLegalPerson', (isLegalPerson, field) =>
          isLegalPerson === 'false'
            ? field
                .required()
                .test('is-cpf-valid', 'CPF inválido.', validateCpf)
            : field.notRequired()
        ),
      email: Yup.string()
        .lowercase()
        .trim()
        .email('Email inválido.')
        .required(),
      isAdmin: Yup.boolean().required(),
      isApproved: Yup.boolean(),
      hasScale: Yup.string(),
      isBuyer: Yup.boolean().required(),
      isConsultant: Yup.boolean().required(),
      isLegalPerson: Yup.string().required(),
      isLogistic: Yup.boolean().required(),
      isSeller: Yup.boolean().required(),
      name: Yup.string()
        .trim()
        .required()
        .test(
          'is-fullname',
          'Informe nome e sobrenome',
          name => name && name.indexOf(' ') !== -1
        ),
      phone: Yup.string()
        .min(14, 'Número de celular inválido.')
        .required(),
      harvestNote: Yup.string(),
      stateRegistrations: Yup.array(
        Yup.string()
          .transform(stateRegistrations =>
            stateRegistrations.replace(/\D/g, '')
          )
          .test('is-state-registration', '', function test(stateRegistrations) {
            const { path, createError, options } = this;
            if (!ie(stateRegistrations)) {
              return createError({
                path,
                message: `A inscrição estadual ${options.originalValue} é inválida. `,
              });
            }
            return true;
          })
      ).when(['isBuyer', 'isSeller'], (isBuyer, isSeller, field) =>
        isBuyer || isSeller ? field.required() : field.notRequired()
      ),
      paymentCreditInDays: Yup.number().when(
        ['isBuyer', 'isApproved'],
        (isBuyer, isApproved, field) =>
          isBuyer && isApproved
            ? field
                .required()
                .test(
                  'is-valid',
                  'A quantidade de dias deve ser entre -15 e 15',
                  days => days > -16 && days < 16
                )
            : field.notRequired()
      ),
    }),

    onSubmit: async formValues => {
      try {
        const formData = new FormData();

        formValues.isLegalPerson = formValues.isLegalPerson === 'true';
        formValues.hasScale = formValues.hasScale === 'true';

        if (formValues.consultant)
          formValues.consultant =
            formValues.consultant._id || formValues.consultant;

        Object.keys(formValues).forEach(field => {
          formData.append(field, formValues[field]);
        });

        if (formValues.addressList.length) {
          formValues.addressList = formValues.addressList.map(a => {
            if (isObject(a.city)) a.city = a.city._id;
            return a;
          });

          formData.set('addressList', JSON.stringify(formValues.addressList));
          formData.set(
            'stateRegistrations',
            JSON.stringify(formValues.stateRegistrations)
          );
        }

        if (formValues.avatar && formValues.avatar._id) {
          formData.set('avatar', formValues.avatar._id);
        }

        if (formValues.documents && formValues.documents.length) {
          const documentsIds = formValues.documents.map(
            document => document._id
          );

          formData.set('documents', JSON.stringify(documentsIds));
        }

        if (newAvatar) formData.append('newAvatar', newAvatar);

        if (newDocuments.length) {
          newDocuments.forEach(file => {
            formData.append('newDocuments', file);
          });
        }

        let res;
        if (formValues._id) {
          const { data } = await axios.patch(
            `/users/${formValues._id}`,
            formData
          );
          res = data;
        } else {
          const { data } = await axios.post('/users/signup', formData);
          res = data;
        }

        if (res.status === 'OK') {
          handleSnackbar(res.message, 'success');

          setTimeout(() => {
            history.push('/users');
          }, 3250);
        }
      } catch (error) {
        formValues.isLegalPerson = formValues.isLegalPerson ? 'true' : 'false';
        formValues.hasScale = formValues.hasScale ? 'true' : 'false';

        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao cadastrar usuário.',
          'error'
        );
      }
    },
  });

  const handleAddressFormClose = () => {
    setAddress(emptyAddress);
    setIsAddressFormOpen(false);
  };

  const handleDeleteAddress = rowData => {
    const newAddressList = addressList.filter(a => a !== rowData);

    setAddressList(newAddressList);
  };

  const handleEditAddress = rowData => {
    setAddress(rowData);
    setIsAddressFormOpen(true);
  };

  const handleFormSubmition = async () => {
    const fields = Object.keys(values);
    const isProfileChecked =
      values.isAdmin ||
      values.isBuyer ||
      values.isConsultant ||
      values.isLogistic ||
      values.isSeller;
    const isAddressValid =
      values.isBuyer || values.isSeller ? !!addressList.length : true;
    const isFormValid =
      !Object.keys(errors).length && isProfileChecked && isAddressValid;

    fields.forEach(f => setFieldTouched(f, true));

    setIsFormValidated(true);
    setProfileChecked(isProfileChecked);
    setAddressValid(isAddressValid);

    if (isFormValid) {
      setFieldValue('addressList', addressList);
      handleSubmit();
    }
  };

  const handleProfileCheck = (field, value) => {
    setIsFormValidated(false);
    setFieldValue(field, value);

    const isProfileChecked =
      values.isAdmin ||
      values.isBuyer ||
      values.isConsultant ||
      values.isLogistic ||
      values.isSeller;

    if (
      (values.isSeller && field !== 'isSeller') ||
      (field === 'isSeller' && value)
    ) {
      setIsUserSeller(true);
    } else {
      setIsUserSeller(false);
    }

    if (
      (values.isBuyer && field !== 'isBuyer') ||
      (field === 'isBuyer' && value)
    ) {
      setIsUserBuyer(true);
    } else {
      setIsUserBuyer(false);
    }

    setProfileChecked(isProfileChecked);
  };

  const handleOnChangeAvatar = () => {
    const avatarInput = window.document.getElementById('avatar');

    if (avatarInput.files && avatarInput.files.length) {
      const reader = new FileReader();

      reader.onload = e => {
        setAvatarUrl(e.target.result);
      };

      reader.readAsDataURL(avatarInput.files[0]);

      setNewAvatar(avatarInput.files[0]);
    }
  };

  const handleClickToChangeAvatar = () => {
    window.document.getElementById('avatar').click();
  };

  const handleRemoveAvatar = () => {
    setAvatarUrl(null);
    setFieldValue('avatar', '');
    setNewAvatar(null);
  };

  const handleRemoveDocument = id => {
    const documentsFiltered = values.documents.filter(
      document => document._id !== id
    );

    setFieldValue('documents', documentsFiltered);
  };

  const handleUploadedDocuments = files => {
    setNewDocuments(files);
  };

  // Load cities
  useEffect(() => {
    const loadData = async () => {
      try {
        if (!cities.length) {
          const { data: res } = await axios.get('/cities');

          if (res.status === 'OK') setCities(res.data);
        }
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Não foi possível buscar as cidades cadastradas no servidor.',
          'error'
        );
      }
    };

    loadData();
  }, [cities.length]); // eslint-disable-line react-hooks/exhaustive-deps

  // Load user data if there is any
  useEffect(() => {
    if (location.state) {
      const { editUser } = location.state;
      const user = {
        ...editUser,
        cnpj: (editUser.cnpj && cnpjMask(editUser.cnpj)) || '',
        cpf: (editUser.cpf && cpfMask(editUser.cpf)) || '',
        email: editUser.email || '',
        isApproved: editUser.isApproved || false,
        hasScale: editUser.hasScale ? 'true' : 'false',
        isLegalPerson: editUser.isLegalPerson ? 'true' : 'false',
        consultant: editUser.consultant || loggedUser,
        harvestNote: editUser.harvestNote || '',
        stateRegistrations: editUser.stateRegistrations || '',
        paymentCreditInDays: editUser.paymentCreditInDays || '',
      };
      const userAddressList = user.addressList.map(a => ({
        ...a,
        longitude: a.location.coordinates[0],
        latitude: a.location.coordinates[1],
        isTaxPaidOnRevenue: a.isTaxPaidOnRevenue ? 'true' : 'false',
        isBuyAddress: a.isBuyAddress ? 'true' : 'false',
      }));

      setAddressList(userAddressList);
      setConsultantName(user.consultant.name);
      setIsUserSeller(editUser.isSeller);
      setIsUserBuyer(editUser.isBuyer);
      setValues(user, false);
    }
  }, [location.state, loggedUser, setValues]);

  return (
    <Paper className={classes.root}>
      <Typography component="h3" variant="h5">
        Dados Pessoais
      </Typography>
      <FormHelperText>Campos com * são obrigatórios.</FormHelperText>
      <form onSubmit={handleSubmit}>
        {/* Personal Data */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              component="fieldset"
              error={touched.isLegalPerson && !!errors.isLegalPerson}
              required
            >
              <FormLabel component="legend">
                Selecione o tipo de pessoa
              </FormLabel>
              <RadioGroup
                name="isLegalPerson"
                onChange={handleChange}
                row
                value={values.isLegalPerson}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Física"
                  value="false"
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Jurídica"
                  value="true"
                />
              </RadioGroup>
              {touched.isLegalPerson && !!errors.isLegalPerson && (
                <FormHelperText className={classes.helperText}>
                  {errors.isLegalPerson}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {values.isLegalPerson === 'true' && (
            <Grid item md xs={12}>
              <TextField
                className={classes.textField}
                error={touched.cnpj && !!errors.cnpj}
                helperText={touched.cnpj && errors.cnpj}
                label="CNPJ"
                margin="normal"
                name="cnpj"
                onBlur={handleBlur}
                onChange={e => setFieldValue('cnpj', cnpjMask(e.target.value))}
                required={values.isLegalPerson === 'true'}
                value={values.cnpj}
              />
            </Grid>
          )}
          {values.isLegalPerson === 'false' && (
            <Grid item md xs={12}>
              <TextField
                className={classes.textField}
                error={touched.cpf && !!errors.cpf}
                helperText={touched.cpf && errors.cpf}
                label="CPF"
                margin="normal"
                name="cpf"
                onBlur={handleBlur}
                onChange={e => setFieldValue('cpf', cpfMask(e.target.value))}
                required={values.isLegalPerson === 'false'}
                value={values.cpf}
              />
            </Grid>
          )}
          <Grid item md={3} xs={12}>
            <TextField
              className={classes.textField}
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              label="Nome Completo"
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.name}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              className={classes.textField}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="email"
              value={values.email}
            />
          </Grid>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.phone && !!errors.phone}
              helperText={touched.phone && errors.phone}
              label="Número do Celular"
              margin="normal"
              name="phone"
              onBlur={handleBlur}
              onChange={e => setFieldValue('phone', phoneMask(e.target.value))}
              required
              type="tel"
              value={values.phone}
            />
          </Grid>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              disabled
              label="Consultor(a)"
              margin="normal"
              name="consultant"
              value={consultantName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl
              component="fieldset"
              error={isFormValidated && !profileChecked}
              required
            >
              <FormLabel component="legend">
                Selecione o(s) perfil(is) do usuário
              </FormLabel>
              <FormGroup row>
                {loggedUser.isAdmin && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isAdmin}
                          color="primary"
                          name="isAdmin"
                          onChange={(_, value) =>
                            handleProfileCheck('isAdmin', value)
                          }
                        />
                      }
                      label="Administrador"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isConsultant}
                          color="primary"
                          name="isConsultant"
                          onChange={(_, value) =>
                            handleProfileCheck('isConsultant', value)
                          }
                        />
                      }
                      label="Consultor"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isLogistic}
                          color="primary"
                          name="isLogistic"
                          onChange={(_, value) =>
                            handleProfileCheck('isLogistic', value)
                          }
                        />
                      }
                      label="Logístico"
                    />
                  </>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isBuyer}
                      color="primary"
                      name="isBuyer"
                      onChange={(_, value) =>
                        handleProfileCheck('isBuyer', value)
                      }
                    />
                  }
                  label="Comprador"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isSeller}
                      color="primary"
                      name="isSeller"
                      onChange={(_, value) =>
                        handleProfileCheck('isSeller', value)
                      }
                    />
                  }
                  label="Produtor"
                />
              </FormGroup>
              {isFormValidated && !profileChecked && (
                <FormHelperText className={classes.helperText}>
                  Selecione pelo menos um perfil.
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {(values.isBuyer || values.isSeller) && loggedUser.isAdmin && (
            <Grid item md={2} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Situação do cadastro</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isApproved}
                        color="primary"
                        name="isApproved"
                        onChange={handleChange}
                      />
                    }
                    value={values.isApproved}
                    label="Aprovado"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}
          {values.isBuyer && loggedUser.isAdmin && (
            <>
              <Grid item md={2} xs={12}>
                <TextField
                  className={classes.textField}
                  error={
                    touched.paymentCreditInDays && !!errors.paymentCreditInDays
                  }
                  helperText={
                    touched.paymentCreditInDays && errors.paymentCreditInDays
                  }
                  label="Crédito de pg."
                  margin="normal"
                  name="paymentCreditInDays"
                  InputProps={{
                    ...addInputAdornment('dias'),
                    inputProps: { min: -15, max: 15 },
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={values.isApproved}
                  type="number"
                  value={values.paymentCreditInDays}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Possui balança</FormLabel>
                  <RadioGroup
                    name="hasScale"
                    onChange={handleChange}
                    row
                    value={values.hasScale}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Sim"
                      value="true"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Não"
                      value="false"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}
          {/* Registration - Inscrição estadual */}
          {(values.isBuyer || values.isSeller) && (
            <Grid item xs={12}>
              <ChipInput
                error={
                  touched.stateRegistrations && !!errors.stateRegistrations
                }
                helperText={
                  touched.stateRegistrations && errors.stateRegistrations
                }
                label="Informe a(s) inscrição(ões) estadual(is) (aperte ENTER ao inserir uma inscrição)"
                fullWidth
                onAdd={chip =>
                  setFieldValue('stateRegistrations', [
                    ...values.stateRegistrations,
                    chip,
                  ])
                }
                onDelete={chipToDelete =>
                  handleDeletestateRegistrations(
                    chipToDelete,
                    values,
                    setFieldValue
                  )
                }
                id="stateRegistrations"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.stateRegistrations}
                required={values.isBuyer || values.isSeller}
              />
            </Grid>
          )}
          {values.isSeller && (
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                error={touched.harvestNote && !!errors.harvestNote}
                helperText={touched.harvestNote && errors.harvestNote}
                label="Observações sobre a colheita"
                margin="normal"
                name="harvestNote"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.harvestNote}
              />
            </Grid>
          )}
        </Grid>

        <Divider className={classes.divider} />

        {/* Addresses */}
        {(values.isBuyer || values.isSeller) && (
          <>
            <div className={classes.addressSectionHeader}>
              <Typography
                className={classes.addressSectionHeaderItem}
                component="h3"
                variant="h5"
              >
                Endereço(s)
              </Typography>
              <Button
                className={classes.addressSectionHeaderItem}
                color="primary"
                onClick={() => setIsAddressFormOpen(true)}
                variant="outlined"
              >
                Adicionar novo endereço
              </Button>
            </div>
            <FormHelperText
              className={classes.helperText}
              error={isFormValidated && !addressValid}
            >
              Um usuário comprador ou produtor deve ter pelo menos um endereço
              cadastrado.
            </FormHelperText>
            <div className={classes.tableContainer}>
              <AddressTable
                address={address}
                addressList={addressList}
                handleDeleteAddress={handleDeleteAddress}
                handleEditAddress={handleEditAddress}
                cities={cities}
                isUserSeller={isUserSeller}
              />
            </div>
            <AddressForm
              address={address}
              addressList={addressList}
              classes={classes}
              handleAddressFormClose={handleAddressFormClose}
              isAddressFormOpen={isAddressFormOpen}
              cities={cities}
              setAddressList={setAddressList}
              isUserSeller={isUserSeller}
              isUserBuyer={isUserBuyer}
            />

            <Divider className={classes.divider} />
          </>
        )}

        {/* Files */}
        <div className={classes.filesSectionHeader}>
          <Typography component="h3" variant="h5">
            Arquivos
          </Typography>
          <FormHelperText>
            Use este espaço para fazer o upload de arquivos deste usuário. São
            aceitos arquivos com até 3 MB.
          </FormHelperText>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={2} xl={1}>
            <FormLabel component="legend">Avatar do usuário</FormLabel>
            <div className={classes.filesSectionAvatarContainer}>
              <input
                accept="image/*"
                id="avatar"
                onChange={handleOnChangeAvatar}
                style={{ display: 'none' }}
                type="file"
              />
              <img
                alt="Avatar do usuário"
                className={classes.filesSectionAvatarPreview}
                src={
                  avatarUrl ||
                  (values.avatar && values.avatar.url) ||
                  'http://placehold.it/120'
                }
              />
              <div>
                <IconButton
                  aria-label="Editar avatar"
                  color="primary"
                  onClick={handleClickToChangeAvatar}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="Remover avatar"
                  color="secondary"
                  onClick={handleRemoveAvatar}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm>
            <FormLabel component="legend">
              Documentos (máximo de 5 arquivos)
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} sm>
                {values.documents.length < 5 && (
                  <DropzoneArea
                    acceptedFiles={[
                      'image/*',
                      'application/pdf',
                      'application/msword',
                      'application/vnd.ms-excel',
                      'text/csv',
                    ]}
                    dropzoneClass={classes.filesSectionDocumentsDropzone}
                    dropzoneParagraphClass={
                      classes.filesSectionDocumentsDropzoneText
                    }
                    dropzoneText="Arraste e solte um arquivo ou clique aqui"
                    filesLimit={
                      values.documents.length ? 5 - values.documents.length : 5
                    }
                    getFileLimitExceedMessage={filesLimit =>
                      `São permitidos apenas mais ${filesLimit} arquivos.`
                    }
                    onChange={handleUploadedDocuments}
                    previewText="Novos documentos:"
                    showAlerts={['error']}
                    showFileNames
                    showPreviews
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                  />
                )}
              </Grid>
              <Grid item xs={12} sm>
                {values.documents && (
                  <ul style={{ listStyleType: 'decimal' }}>
                    {values.documents.map(document => (
                      <li
                        className={classes.filesSectionDocumentsFile}
                        key={document._id}
                      >
                        <a
                          href={document.url}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {document.name}
                        </a>
                        <button
                          className={classes.filesSectionDocumentsDeleteFile}
                          onClick={() => handleRemoveDocument(document._id)}
                          type="button"
                        >
                          excluir
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <div className={classes.bottomButtonsContainer}>
          <Button
            className={classes.bottomButton}
            color="primary"
            disabled={isSubmitting}
            onClick={handleFormSubmition}
            type="button"
            variant="contained"
          >
            {values._id ? 'Confirmar Alterações' : 'Cadastrar'}
          </Button>
        </div>
      </form>
      <Snackbar
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        open={snackbar.isOpen}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          message={snackbar.message}
        />
      </Snackbar>
    </Paper>
  );
};

AddressForm.propTypes = {
  address: PropTypes.oneOfType([PropTypes.object]),
  addressList: PropTypes.oneOfType([PropTypes.array]),
  classes: PropTypes.oneOfType([PropTypes.object]),
  handleAddressFormClose: PropTypes.func,
  isAddressFormOpen: PropTypes.bool,
  cities: PropTypes.oneOfType([PropTypes.array]),
  setAddressList: PropTypes.func,
  isUserSeller: PropTypes.bool,
  isUserBuyer: PropTypes.bool,
};

AddressForm.defaultProps = {
  address: {},
  addressList: [],
  classes: {},
  handleAddressFormClose: null,
  isAddressFormOpen: false,
  cities: [],
  setAddressList: null,
  isUserSeller: false,
  isUserBuyer: false,
};

AddressTable.propTypes = {
  addressList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleDeleteAddress: PropTypes.func.isRequired,
  cities: PropTypes.oneOfType([PropTypes.array]).isRequired,
  isUserSeller: PropTypes.bool.isRequired,
};

EditUserPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
};

EditUserPage.defaultProps = {
  location: {},
};

export default EditUserPage;
