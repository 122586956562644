import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import axios from 'axios';
import { CircularProgress, makeStyles } from '@material-ui/core';

import { tableLocalization, cpfMask } from '../../../../_metronic/utils/utils';

const useStyles = makeStyles({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
});

export default function TruckPage() {
  const history = useHistory();
  const [tucks, setTrucks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const loadTrucks = async () => {
      setIsLoading(true);
      try {
        const { data: res } = await axios.get('/trucks');
        if (res && res.data) {
          setTrucks(res.data);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      } finally {
        setIsLoading(false);
      }
    };

    loadTrucks();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <MaterialTable
          columns={[
            {
              field: 'driver',
              title: 'Motorista',
              width: null,
            },
            {
              field: 'cpf',
              title: 'CPF',
              render: ({ cpf }) => cpfMask(cpf),
            },
            {
              field: 'plate',
              title: 'Placa',
            },
            {
              field: 'type.vehicle',
              title: 'Veículo',
            },
            {
              field: 'type.scaleWeight',
              title: 'Peso de balança (kg)',
              type: 'numeric',
            },
            {
              field: 'type.capacities[1]',
              title: 'Capacidade máx. (kg)',
              type: 'numeric',
            },
            {
              field: 'referral.name',
              emptyValue: '-',
              title: 'Indicação',
            },
            {
              field: 'phone',
              title: 'Telefone',
              emptyValue: '-',
            },
            {
              field: 'whatsapp',
              title: 'WhatsApp',
            },
            {
              field: 'email',
              title: 'Email',
            },
          ]}
          data={tucks}
          options={{
            columnsButton: true,
            exportButton: true,
            exportFileName: 'Caminhões',
            pageSize: 10,
            pageSizeOptions: [10, 30, 50, 100],
            showTitle: false,
            toolbarButtonAlignment: 'left',
          }}
          localization={tableLocalization('caminhão', 'caminhões')}
          actions={[
            {
              icon: 'create',
              tooltip: 'Editar caminhão',
              onClick: (_, rowData) => {
                return history.push('/truck-form', {
                  editTruck: rowData,
                  truckId: rowData._id,
                });
              },
            },
            {
              icon: 'add_box',
              tooltip: 'Adicionar caminhão',
              isFreeAction: true,
              onClick: () => history.push('/truck-form'),
            },
          ]}
          editable={{
            onRowDelete: async oldData => {
              try {
                const newTrucks = tucks.filter(f => f !== oldData);

                const { data: res } = await axios.delete(
                  `/trucks/${oldData._id}`
                );

                if (res.status === 'OK') {
                  setTrucks(newTrucks);
                }
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
              }
            },
          }}
        />
      )}
    </>
  );
}
