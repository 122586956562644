import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';

export default function CustomField({ field, value, xs, sm, md }) {
  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <Typography>{field}</Typography>
      <Typography align="left" gutterBottom variant="h6">
        {value}
      </Typography>
    </Grid>
  );
}

CustomField.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

CustomField.defaultProps = {
  field: 'Carregando...',
  value: 'Carregando...',
  xs: true,
  sm: true,
  md: true,
};
