/* eslint-disable no-console */
/**
 * Metronic utils
 */
export const removeCSSClass = (ele, cls) => {
  const reg = new RegExp(`(\\s|^)${cls}(\\s|$)`);
  ele.className = ele.className.replace(reg, ' ');
};

export const addCSSClass = (ele, cls) => {
  ele.classList.add(cls);
};

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const setupAxios = (axios, store) => {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { token },
      } = store.getState();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      config.baseURL = process.env.REACT_APP_API_URL;

      return config;
    },
    err => Promise.reject(err)
  );
};

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export const removeStorage = key => {
  try {
    localStorage.setItem(key, '');
    localStorage.setItem(`${key}_expiresIn`, '');
  } catch (e) {
    console.log(
      `removeStorage: Error removing key [${key}] from localStorage: ${JSON.stringify(
        e
      )}`
    );
    return false;
  }

  return true;
};

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export const getStorage = key => {
  const now = Date.now(); // epoch time, lets deal only with integer

  // set expiration for storage
  let expiresIn = localStorage.getItem(`${key}_expiresIn`);
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  }

  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.log(
      `getStorage: Error reading key [${key}] from localStorage: ${JSON.stringify(
        e
      )}`
    );
    return null;
  }
};

/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export const setStorage = (key, value, expires) => {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); // millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;

  try {
    localStorage.setItem(key, value);
    localStorage.setItem(`${key}_expiresIn`, schedule);
  } catch (e) {
    console.log(
      `setStorage: Error setting key [${key}] in localStorage: ${JSON.stringify(
        e
      )}`
    );
    return false;
  }

  return true;
};

/**
 * Custom utils
 */
export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const cnpjMask = value =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const cpfMask = value =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const isObject = value =>
  value && typeof value === 'object' && value.constructor === Object;

export const localeDecimalNumber = value =>
  String(value).indexOf('.') !== -1 ? String(value).replace('.', ',') : value;

export const phoneMask = value =>
  value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');

export const validateCnpj = cnpj => {
  if (!cnpj) return false;

  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length !== 14) return false;

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  let length = cnpj.length - 2;
  let numbers = cnpj.substring(0, length);
  let accumulator = 0;
  let pos = length - 7;

  const digits = cnpj.substring(length);

  for (let i = length; i >= 1; i -= 1) {
    accumulator += Number(numbers[length - i]) * pos;
    pos -= 1;

    if (pos < 2) pos = 9;
  }

  let result = accumulator % 11 < 2 ? 0 : 11 - (accumulator % 11);

  if (result !== Number(digits[0])) return false;

  length += 1;
  numbers = cnpj.substring(0, length);
  accumulator = 0;
  pos = length - 7;

  for (let i = length; i >= 1; i -= 1) {
    accumulator += Number(numbers[length - i]) * pos;
    pos -= 1;

    if (pos < 2) pos = 9;
  }

  result = accumulator % 11 < 2 ? 0 : 11 - (accumulator % 11);

  if (result !== Number(digits[1])) return false;

  return true;
};

export const validateCpf = cpf => {
  if (!cpf) return false;

  cpf = cpf.replace(/\D/g, '');

  if (
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;

  let accumulator = 0;
  let rest;

  for (let i = 1; i <= 9; i += 1) {
    accumulator += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (accumulator * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10), 10)) return false;

  accumulator = 0;

  for (let i = 1; i <= 10; i += 1) {
    accumulator += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (accumulator * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(10, 11), 10)) return false;

  return true;
};

export const licensePlateMask = value =>
  value.replace(/^(\D{3})(\d)/g, '$1-$2');

export const validateLicensePlate = licensePlate => {
  if (!licensePlate) return false;

  licensePlate = licensePlate.replace(/[^a-zA-Z0-9]/g, '');

  const regexLicensePlate = /^[a-zA-Z]{3}[0-9]{4}$/;
  const regexMercosulLicensePlate = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/;

  return (
    regexLicensePlate.test(licensePlate) ||
    regexMercosulLicensePlate.test(licensePlate)
  );
};

export const tableLocalization = (model, modelPlural = `${model}s`) => {
  modelPlural = `${modelPlural[0].toUpperCase()}${modelPlural.slice(1)}`;

  return {
    body: {
      emptyDataSourceMessage: `Nenhum ${model} disponível.`,
      addTooltip: 'Adicionar',
      deleteTooltip: 'Remover',
      editTooltip: 'Editar',
      filterRow: {
        filterTooltip: 'Filtrar',
      },
      editRow: {
        deleteText: `Remover ${model}? Não será possível desfazer esta ação.`,
        cancelTooltip: 'Cancelar',
        saveTooltip: 'Confirmar',
      },
    },
    grouping: {
      placeholder: 'Arrastar cabeçalhos...',
    },
    header: {
      actions: 'Ações',
    },
    pagination: {
      labelDisplayedRows: `Exibindo {from} à {to} de {count} ${modelPlural.toLowerCase()}`,
      labelRowsSelect: `${modelPlural}`,
      labelRowsPerPage: `${modelPlural} por página: `,
      firstAriaLabel: 'Primeira página',
      firstTooltip: 'Primeira página',
      previousAriaLabel: 'Página Anterior',
      previousTooltip: 'Página Anterior',
      nextAriaLabel: 'Próxima página',
      nextTooltip: 'Próxima página',
      lastAriaLabel: 'Última página',
      lastTooltip: 'Última página',
    },
    toolbar: {
      addRemoveColumns: 'Exibir ou ocultar colunas',
      nRowsSelected: '{0} coluna(s) selecionadas',
      showColumnsTitle: 'Mostrar Colunas',
      showColumnsAriaLabel: 'Mostrar Colunas',
      exportTitle: 'Exportar',
      exportAriaLabel: 'Exportar',
      exportName: 'Exportar como CSV',
      searchTooltip: `Buscar ${model}`,
      searchPlaceholder: `Buscar ${model}`,
    },
  };
};

export const dynamicArraySort = property => {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return (a, b) => {
    if (sortOrder === -1) {
      return b[property]
        .toString()
        .localeCompare(a[property].toString(), undefined, { numeric: true });
    }
    return a[property]
      .toString()
      .localeCompare(b[property].toString(), undefined, { numeric: true });
  };
};

export const getStatus = (isDone, isCanceled) => {
  if (isDone) {
    return 'Fechada';
  }
  if (isCanceled) {
    return 'Cancelada';
  }
  return 'Em aberto';
};

export const getGrain = grain => {
  const grains = [
    { grain: 'Milho', image: toAbsoluteUrl('/media/grains/corn.jpg') },
    { grain: 'Soja', image: toAbsoluteUrl('/media/grains/soybean.jpg') },
    { grain: 'Sorgo', image: toAbsoluteUrl('/media/grains/sorghum.jpg') },
  ];

  return grains[grain - 1];
};
