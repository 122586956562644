import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import axios from 'axios';
import PropTypes from 'prop-types';
import { InputAdornment, Snackbar, TextField } from '@material-ui/core';

import { DecimalInput } from '../../../components/CustomInputs';
import SnackbarContentWrapper from '../../../components/SnackbarContentWrapper';
import { tableLocalization } from '../../../../_metronic/utils/utils';

const addInputAdornment = (adornment, position = 'end') => ({
  [`${position}Adornment`]: (
    <InputAdornment position={position}>{adornment}</InputAdornment>
  ),
});

const defaultErrors = {
  kmMin: '',
  kmMax: '',
  pricePerKm: '',
};

const defaultTouched = {
  kmMin: false,
  kmMax: false,
  pricePerKm: false,
};

const customInputPropTypes = {
  propTypes: {
    onChange: PropTypes.func.isRequired,
    rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  },
  defaultProps: {
    value: '',
  },
};

export default function FreightsPage() {
  const user = useSelector(state => state.auth.user);

  const [freights, setFreights] = useState([]);
  const [errors, setErrors] = useState(defaultErrors);
  const [touched, setTouched] = useState(defaultTouched);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    variant: 'success',
  });

  const handleSnackbar = (
    message = '',
    variant = snackbar.variant || 'success'
  ) => {
    setSnackbar({
      isOpen: !!message,
      message,
      variant,
    });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSnackbar();
  };

  const handleChange = (field, value, rowData) => {
    const newErrors = {};

    if (!value) {
      newErrors[field] = 'Campo obrigatório.';

      setErrors({ ...errors, ...newErrors });
    } else if (field === 'pricePerKm') {
      newErrors[field] =
        Number(value) <= 0
          ? 'O preço do km deve ser maior do que R$ 0,00.'
          : '';

      setErrors({ ...errors, ...newErrors });
    } else {
      if (field === 'kmMin') {
        newErrors[field] =
          Number(value) >= Number(rowData.kmMax)
            ? 'O km mínimo deve ser menor do que o máximo.'
            : '';
      } else if (field === 'kmMax') {
        newErrors[field] =
          Number(value) <= Number(rowData.kmMin)
            ? 'O km máximo deve ser maior do que o mínimo.'
            : '';
      }

      const duplicatedValue = freights.find(f => {
        let found = false;

        if (field === 'kmMin') {
          found =
            Number(f.kmMin) <= Number(rowData.kmMax) &&
            Number(f.kmMax) >= Number(value);
        } else {
          found =
            Number(f.kmMin) <= Number(value) &&
            Number(f.kmMax) >= Number(rowData.kmMin);
        }

        return found && rowData._id !== f._id;
      });

      if (duplicatedValue) {
        newErrors.kmMin = `Km inválido, já existe preço entre ${duplicatedValue.kmMin}km e ${duplicatedValue.kmMax}km.`;
        newErrors.kmMax = `Km inválido, já existe preço entre ${duplicatedValue.kmMin}km e ${duplicatedValue.kmMax}km.`;
      }

      setErrors({ ...defaultErrors, ...newErrors });
    }

    setTouched({ ...defaultTouched, [field]: true });
  };

  const KmMinInput = ({ onChange, rowData, value: oldValue }) => (
    <TextField
      autoFocus={touched.kmMin}
      error={!!errors.kmMin}
      helperText={errors.kmMin}
      InputProps={{
        inputComponent: DecimalInput,
      }}
      name="kmMin"
      onChange={({ target: { value } }) => {
        onChange(value);
        handleChange('kmMin', value, rowData);
      }}
      required
      value={oldValue}
    />
  );
  KmMinInput.propTypes = customInputPropTypes.propTypes;
  KmMinInput.defaultProps = customInputPropTypes.defaultProps;

  const KmMaxInput = ({ onChange, rowData, value: oldValue }) => (
    <TextField
      autoFocus={touched.kmMax}
      error={!!errors.kmMax}
      helperText={errors.kmMax}
      InputProps={{
        inputComponent: DecimalInput,
      }}
      name="kmMax"
      onChange={({ target: { value } }) => {
        onChange(value);
        handleChange('kmMax', value, rowData);
      }}
      required
      value={oldValue}
    />
  );
  KmMaxInput.propTypes = customInputPropTypes.propTypes;
  KmMaxInput.defaultProps = customInputPropTypes.defaultProps;

  const PricePerKmInput = ({ onChange, rowData, value: oldValue }) => (
    <TextField
      autoFocus={touched.pricePerKm}
      error={!!errors.pricePerKm}
      helperText={errors.pricePerKm}
      InputProps={{
        ...addInputAdornment('R$', 'start'),
        inputComponent: DecimalInput,
      }}
      name="pricePerKm"
      onChange={({ target: { value } }) => {
        onChange(value);
        handleChange('pricePerKm', value, rowData);
      }}
      required
      value={oldValue}
    />
  );
  PricePerKmInput.propTypes = customInputPropTypes.propTypes;
  PricePerKmInput.defaultProps = customInputPropTypes.defaultProps;

  useEffect(() => {
    const loadFreights = async () => {
      try {
        const { data: res } = await axios.get('/freights');

        if (res.status === 'OK') setFreights(res.data);
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao buscar fretes.',
          'error'
        );
      }
    };

    loadFreights();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MaterialTable
        columns={[
          {
            defaultSort: 'asc',
            editComponent: KmMinInput,
            field: 'kmMin',
            title: 'Km mínimo',
            type: 'numeric',
          },
          {
            editComponent: KmMaxInput,
            field: 'kmMax',
            title: 'Km máximo',
            type: 'numeric',
          },
          {
            currencySetting: {
              currencyCode: 'BRL',
              locale: 'pt',
              maximumFractionDigits: 3,
              minimumFractionDigits: 2,
            },
            editComponent: PricePerKmInput,
            field: 'pricePerKm',
            title: 'Preço por km',
            type: 'currency',
          },
          {
            editable: 'never',
            emptyValue: 'neste momento',
            field: 'updatedAt',
            title: 'Atualizado em',
            type: 'datetime',
          },
          {
            editable: 'never',
            emptyValue: user.name,
            field: 'updatedBy.name',
            title: 'Atualizado por',
            render: ({ updatedBy: { name } }) => name,
          },
        ]}
        data={freights}
        editable={{
          onRowAdd: async newFreight => {
            const hasErrors = Object.values(errors).some(e => e !== '');

            if (hasErrors) {
              handleSnackbar(
                'Erro ao adicionar frete, os valores são inválidos.',
                'error'
              );
            } else {
              try {
                const { data: res } = await axios.post(`/freights`, newFreight);

                if (res.status === 'OK') {
                  newFreight._id = res.data._id;

                  setFreights([...freights, newFreight]);
                  handleSnackbar(res.message, 'success');
                }
              } catch (error) {
                handleSnackbar(
                  (error.response && error.response.data.message) ||
                    'Erro ao adicionar frete.',
                  'error'
                );
              }

              setTouched(defaultTouched);
            }
          },
          onRowUpdate: async (editedFreight, oldData) => {
            const hasErrors = Object.values(errors).some(e => e !== '');

            if (hasErrors) {
              handleSnackbar(
                'Erro ao atualizar frete, os valores são inválidos.',
                'error'
              );
            } else {
              try {
                const newFreights = [...freights];

                const { data: res } = await axios.patch(
                  `/freights/${oldData._id}`,
                  editedFreight
                );

                if (res.status === 'OK') {
                  newFreights[newFreights.indexOf(oldData)] = editedFreight;

                  setFreights(newFreights);
                  handleSnackbar(res.message, 'success');
                }
              } catch (error) {
                handleSnackbar(
                  (error.response && error.response.data.message) ||
                    'Erro ao atualizar frete.',
                  'error'
                );
              }

              setTouched(defaultTouched);
            }
          },
          onRowDelete: async oldData => {
            try {
              const newFreights = freights.filter(f => f !== oldData);

              const { data: res } = await axios.delete(
                `/freights/${oldData._id}`
              );

              if (res.status === 'OK') {
                setFreights(newFreights);
                handleSnackbar(res.message, 'success');
              }
            } catch (error) {
              handleSnackbar(
                (error.response && error.response.data.message) ||
                  'Erro ao remover frete.',
                'error'
              );
            }
          },
        }}
        localization={tableLocalization('frete')}
        options={{
          columnsButton: true,
          exportAllData: true,
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 30, 50, 100],
          showTitle: false,
          toolbarButtonAlignment: 'left',
        }}
        title="Fretes"
      />
      <Snackbar
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        open={snackbar.isOpen}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          message={snackbar.message}
        />
      </Snackbar>
    </>
  );
}
