import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale'; // eslint-disable-line
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    typography: {
      fontFamily: ['Poppins'].join(','),
    },

    palette: {
      contrastThreshold: 2,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#006837',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: '#CA0813',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#1CA540',
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#DD2E1A',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      warning: {
        main: '#F4B300',
      },
      info: {
        main: '#026CDD',
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
  },
  ptBR
);

export default function ThemeProvider({ children }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
