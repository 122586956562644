import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as HandeshakeIcon } from '../assets/layout-svg-icons/Agreement.svg';
import { ReactComponent as ReceiptIcon } from '../assets/layout-svg-icons/Receipt.svg';

export default function MenuItemText({ item, parentItem }) {
  const renderIcon = () => {
    if (item.icon) {
      if (item.icon === 'handshake') {
        return (
          <i className="kt-menu__link-icon flaticon2-handshake">
            <HandeshakeIcon />
          </i>
        );
      }
      if (item.icon === 'receipt') {
        return (
          <i className="kt-menu__link-icon flaticon2-receipt">
            <ReceiptIcon />
          </i>
        );
      }
      return <i className={`kt-menu__link-icon ${item.icon}`} />;
    }
    return null;
  };

  return (
    <>
      {renderIcon()}

      {parentItem && parentItem.bullet === 'dot' && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
          <span />
        </i>
      )}

      {parentItem && parentItem.bullet === 'line' && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
          <span />
        </i>
      )}

      <span className="kt-menu__link-text" style={{ whiteSpace: 'nowrap' }}>
        {!item.translate ? (
          item.title
        ) : (
          <FormattedMessage id={item.translate} defaultMessage={item.title} />
        )}
      </span>

      {item.badge && (
        <span className="kt-menu__link-badge">
          <span className={`kt-badge ${item.badge.type}`}>
            {item.badge.value}
          </span>
        </span>
      )}

      {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}
    </>
  );
}
