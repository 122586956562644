/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';

import { toAbsoluteUrl } from '../../../_metronic';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';

const UserProfile = ({ user, showHi, showAvatar, showBadge }) => {
  const userFirstName = user.name.split(' ')[0];
  const userInitial = user.name[0];

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--user"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div className="kt-header__topbar-user">
          <span className="kt-header__topbar-username kt-hidden-mobile">
            {userFirstName}
          </span>

          {/* {showAvatar && <img alt="Pic" src={user.pic} />} */}

          <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
            {userInitial}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div
          className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              '/media/bg/soybean-field-bg.jpg'
            )})`,
          }}
        >
          <div className="kt-user-card__avatar">
            {/* <img alt="Pic" className="kt-hidden" src={user.pic} /> */}
            <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
              {userInitial}
            </span>
          </div>
          <div className="kt-user-card__name">{user.name}</div>
        </div>
        <div className="kt-notification">
          <Link to="/profile" className="kt-notification__item">
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title kt-font-bold">
                Meu Perfil
              </div>
              <div className="kt-notification__item-time">
                Configurações da conta e mais
              </div>
            </div>
          </Link>
          <div className="kt-notification__custom">
            <Link to="/logout" className="btn btn-label-brand btn-sm btn-bold">
              Sair
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(UserProfile);
