import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { formatPrice, getStatus, getGrain } from '../../_metronic/utils/utils';
import CustomField from './CustomField';

export default function ContractDetails({ contractId }) {
  const [contract, setContract] = useState({});

  useEffect(() => {
    const loadContracts = async () => {
      try {
        const { data: res } = await axios.get(`/transactions/${contractId}`);

        if (res && res.data) setContract(res.data);
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };

    if (contractId) loadContracts();
  }, [contractId]);

  const contractFields = useMemo(() => {
    if (contract && contract.order && contract.offer) {
      let originAndSeller;
      let destinationAndBuyer;

      if (contract.order.isBuying) {
        destinationAndBuyer = 'order';
        originAndSeller = 'offer';
      } else {
        destinationAndBuyer = 'offer';
        originAndSeller = 'order';
      }

      const processedData = contract.offer.processedData.filter(value => {
        if (contract.offer.isBuying) {
          return value.distance.from === contract.order.address._id;
        }
        return value.distance.to === contract.order.address._id;
      });

      return {
        grain: getGrain(contract.order.grain).grain,
        status: getStatus(contract.order.isDone, false),
        destination: `${contract[destinationAndBuyer].address.name}, ${contract[destinationAndBuyer].address.city.name} - ${contract[destinationAndBuyer].address.city.uf}`,
        origin: `${contract[originAndSeller].address.name}, ${contract[originAndSeller].address.city.name} - ${contract[originAndSeller].address.city.uf}`,
        buyer: contract[destinationAndBuyer].createdBy.name,
        seller: contract[originAndSeller].createdBy.name,
        type: contract.order.isBuying ? 'Compra' : 'Venda',
        pricePerBag: String(formatPrice(contract.order.bagPrice)),
        totalPrice: String(
          formatPrice(contract.order.amount * contract.order.bagPrice)
        ),
        createdAt: format(new Date(String(contract.createdAt)), 'dd/MM/yyyy'),
        amount: `${contract.order.amount} sacas`,
        dirtRoadFreightPrice: formatPrice(
          processedData[0].dirtRoadFreightPrice
        ),
        dirtRoadKm: String(processedData[0].dirtRoadKm),
        dirtRoadKmPrice: formatPrice(processedData[0].dirtRoadKmPrice),
        freightPrice: formatPrice(processedData[0].freightPrice),
        freightPricePerKm: formatPrice(processedData[0].freightPricePerKm),
        inKm: String(processedData[0].distance.inKm),
        minFreightPrice: formatPrice(processedData[0].minFreightPrice),
        fullFreightPrice: formatPrice(processedData[0].fullFreightPrice),
      };
    }
    return {};
  }, [contract]);

  return (
    <>
      <Grid container spacing={2}>
        <CustomField
          field="Vendedor"
          value={contractFields.seller}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Comprador"
          value={contractFields.buyer}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Tipo da oferta"
          value={contractFields.type}
          xs={12}
          sm={6}
        />
        <CustomField field="Grão" value={contractFields.grain} xs={12} sm={6} />
      </Grid>
      <Grid container spacing={2}>
        <CustomField
          field="Distância asfaltada (km)"
          value={contractFields.inKm}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Estrada de chão (km)"
          value={contractFields.dirtRoadKm}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Valor mínimo do frete"
          value={contractFields.minFreightPrice}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Valor do km"
          value={contractFields.freightPricePerKm}
          xs={12}
          sm={6}
        />
      </Grid>
      <Grid container spacing={2}>
        <CustomField
          field="Valor do km (chão)"
          value={contractFields.dirtRoadKmPrice}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Frete por saca"
          value={contractFields.freightPrice}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Frete por saca (chão)"
          value={contractFields.dirtRoadFreightPrice}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Frete total"
          value={contractFields.fullFreightPrice}
          xs={12}
          sm={6}
        />
      </Grid>
      <Grid container spacing={2}>
        <CustomField
          field="Preço por saca"
          value={contractFields.pricePerBag}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Quantidade negociada"
          value={contractFields.amount}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Valor total"
          value={contractFields.totalPrice}
          xs={12}
          sm={6}
        />
        <CustomField
          field="Situação"
          value={contractFields.status}
          xs={12}
          sm={6}
        />
      </Grid>
      <Grid container spacing={2}>
        <CustomField
          field="Criado em"
          value={contractFields.createdAt}
          xs={12}
          sm={6}
          md={3}
        />
        <CustomField
          field="Local de coleta"
          value={contractFields.origin}
          xs={12}
          sm={6}
          md={3}
        />
        <CustomField
          field="Local de entrega"
          value={contractFields.destination}
          xs={12}
          sm={6}
        />
      </Grid>
    </>
  );
}

ContractDetails.propTypes = {
  contractId: PropTypes.string,
};

ContractDetails.defaultProps = {
  contractId: '',
};
