/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';

import { LayoutContextConsumer } from '../LayoutContext';
import * as builder from '../../ducks/builder';

// import BreadCrumbs from './components/BreadCrumbs';

const SubHeader = ({
  subheaderCssClasses,
  subheaderContainerCssClasses,
  subheaderMobileToggle,
}) => {
  return (
    <div
      id="kt_subheader"
      className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
    >
      <div className={`kt-container ${subheaderContainerCssClasses}`}>
        <div className="kt-subheader__main">
          {subheaderMobileToggle && (
            <button
              className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
              id="kt_subheader_mobile_toggle"
              type="button"
            >
              <span />
            </button>
          )}

          <LayoutContextConsumer>
            {({ subheader: { /* breadcrumb, */ desc, title } }) => (
              <>
                <h3 className="kt-subheader__title">{title}</h3>
                <span className="kt-subheader__desc">{desc}</span>
                {/* <BreadCrumbs items={breadcrumb} /> */}
              </>
            )}
          </LayoutContextConsumer>

          {/* <a
            href="#"
            className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
          >
            Add New
          </a> */}
        </div>

        {/* <div className="kt-subheader__toolbar">
          <div className="kt-subheader__wrapper">
            <button type="button" className="btn kt-subheader__btn-primary">
              Actions &nbsp;
              <SortNum1Icon className="kt-svg-icon kt-svg-icon--sm" />
            </button>
            <QuickActions />
          </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    'subheader.mobile-toggle'
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true,
  }),
});

export default withRouter(connect(mapStateToProps)(SubHeader));
