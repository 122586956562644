import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export const DecimalInput = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    allowNegative={false}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={3}
    isNumericString
  />
);

DecimalInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
