import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { format, isAfter, addDays } from 'date-fns';
import { pt } from 'date-fns/esm/locale';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import Chart from 'react-apexcharts';
import { toInteger } from 'lodash';
import { DateRangePicker } from 'react-date-range';
import {
  formatPrice,
  tableLocalization,
  getGrain,
} from '../../../_metronic/utils/utils';
import {
  defaultStaticRanges,
  defaultInputRanges,
} from '../../../_metronic/utils/definedRanges';
import OrdersWidget from '../../widgets/OrdersWidget';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const currency = {
  type: 'currency',
  currencySetting: {
    currencyCode: 'BRL',
    locale: 'pt',
    maximumFractionDigits: 3,
    minimumFractionDigits: 2,
  },
};

const ContractTable = ({ contract }) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <MaterialTable
        columns={[
          {
            field: 'grainName',
            title: 'Grão',
            width: null,
          },
          {
            field: 'createdAt',
            title: 'Firmado em',
            type: 'datetime',
          },
          {
            field: 'seller',
            title: 'Vendedor',
          },
          {
            field: 'buyer',
            title: 'Comprador',
          },
          {
            field: 'amountOrdered',
            title: 'Quantidade de sacas',
            type: 'numeric',
          },
          {
            field: 'formattedFinalPrice',
            title: 'Valor total',
            ...currency,
          },
          {
            field: 'origin',
            title: 'Local de coleta',
          },
          {
            field: 'destination',
            title: 'Local de entrega',
          },
        ]}
        title="Últimos contratos"
        data={contract}
        options={{
          padding: 'dense',
          paging: false,
        }}
        localization={tableLocalization('contrato')}
      />
    </div>
  );
};

const generateGrainSeries = (name, type, grain, data, dataType, dateArray) => {
  return {
    name,
    type,
    data: dateArray
      .map(date =>
        data
          .filter(dataObject => dataObject._id.grain === grain)
          .find(
            dataObject =>
              new Date(
                dataObject._id.year,
                dataObject._id.month - 1,
                dataObject._id.dayOfMonth
              ).valueOf() === date
          )
      )
      .map(dataObject => (dataObject ? dataObject[dataType] : 0)),
  };
};

const generateSeriesContractsOffers = (name, data, dataType) => {
  return {
    name,
    data: data.map(dataObject => {
      return {
        x: Date.parse(
          new Date(
            dataObject._id.year,
            dataObject._id.month - 1,
            dataObject._id.dayOfMonth
          )
        ),
        y: dataObject[dataType],
      };
    }),
  };
};

const sortData = (a, b) =>
  isAfter(
    new Date(a._id.year, a._id.month - 1, a._id.dayOfMonth),
    new Date(b._id.year, b._id.month - 1, b._id.dayOfMonth)
  )
    ? 1
    : -1;

export default function Dashboard() {
  const loggedUser = useSelector(state => state.auth.user);

  const [contracts, setContracts] = useState([]);
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const [params, setParams] = useState({
    filter: false,
    startDate: '',
    endDate: '',
  });
  const [transactions, setTransactions] = useState([]);
  const [grain, setGrain] = useState([]);
  const [totalContractsPerPeriod, setTotalContractsPerPeriod] = useState({});
  const [offers, setOffers] = useState([]);
  const [grainOffer, setGrainOffer] = useState([]);
  const [totalOffersPerPeriod, setTotalOffersPerPeriod] = useState({});
  const [filterByPeriod, setFilterByPeriod] = useState(false);

  const handleFilter = () => {
    const filter = !!(dateFilter[0].startDate && dateFilter[0].endDate);
    if (filter)
      setParams({
        filter,
        startDate: dateFilter[0].startDate,
        endDate: dateFilter[0].endDate,
      });
  };

  const totalContracts = useMemo(() => {
    return {
      options: {
        chart: {},
        title: {
          text: 'Quantidade total de contratos e ofertas em aberto',
          align: 'left',
          style: {
            fontFamily: 'Poppins',
            fontWeight: '500',
          },
        },
        yaxis: {
          labels: {
            formatter: val => toInteger(val),
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: val =>
              Number.isNaN(new Date(val))
                ? val
                : format(new Date(val), 'dd/MM/yy'),
          },
        },
        tooltip: {
          x: {
            formatter: val =>
              Number.isNaN(new Date(val))
                ? val
                : format(new Date(val), 'dd/MM/yy'),
          },
        },
      },
      series: [
        generateSeriesContractsOffers(
          'Contratos firmados neste dia',
          transactions,
          'totalContracts'
        ),
        generateSeriesContractsOffers(
          'Ofertas abertas neste dia',
          offers,
          'totalOffers'
        ),
      ],
    };
  }, [transactions, offers]);

  const totalContractPrice = useMemo(() => {
    return {
      options: {
        chart: {},
        title: {
          text: 'Valor total dos contratos e ofertas em aberto',
          align: 'left',
          style: {
            fontFamily: 'Poppins',
            fontWeight: '500',
          },
        },
        yaxis: {
          labels: {
            formatter: val => formatPrice(val),
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: val =>
              Number.isNaN(new Date(val))
                ? val
                : format(new Date(val), 'dd/MM/yy'),
          },
        },
        tooltip: {
          x: {
            formatter: val =>
              Number.isNaN(new Date(val))
                ? val
                : format(new Date(val), 'dd/MM/yy'),
          },
        },
      },
      series: [
        generateSeriesContractsOffers(
          'Valor total dos contratos neste dia',
          transactions,
          'totalContractPrice'
        ),
        generateSeriesContractsOffers(
          'Valor total das ofertas abertas neste dia',
          offers,
          'totalOffersPrice'
        ),
      ],
    };
  }, [transactions, offers]);

  useEffect(() => {
    const loadContracts = async () => {
      try {
        const { data: res } = await axios.get(
          `/transactions/consultant/${loggedUser._id}`,
          {
            params: {
              limit: 5,
            },
          }
        );

        if (res && res.status === 'OK') {
          const formattedContracts = res.data.map(contract => ({
            ...contract,
            formattedFinalPrice: contract.finalPrice * contract.amountOrdered,
            grainName: getGrain(contract.grain).grain,
          }));

          setContracts(formattedContracts);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };

    loadContracts();
  }, [loggedUser]);

  useEffect(() => {
    const loadTransaction = async () => {
      try {
        const { data: res } = await axios.get('dashboard', {
          params,
        });

        if (res && res.status === 'OK') {
          const transactionResponse = res.data.transactions;
          const offerResponse = res.data.offers;

          transactionResponse.totalContracts.sort(sortData);
          transactionResponse.totalGrain.sort(sortData);
          offerResponse.totalOffers.sort(sortData);
          offerResponse.totalGrain.sort(sortData);

          setTransactions(transactionResponse.totalContracts);
          setGrain(transactionResponse.totalGrain);
          setTotalContractsPerPeriod(
            transactionResponse.totalContractsPerPeriod[0]
          );

          setOffers(offerResponse.totalOffers);
          setGrainOffer(offerResponse.totalGrain);
          setTotalOffersPerPeriod(offerResponse.totalOffersPerPeriod[0]);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };

    loadTransaction();
  }, [params]);

  const totalAmountOrdered = useMemo(() => {
    if (grain && grain.length > 0) {
      const allDates = grain.map(g =>
        new Date(g._id.year, g._id.month - 1, g._id.dayOfMonth).valueOf()
      );

      const maxTotalAmount = Math.max(
        ...grain.map(object => object.totalAmountOrdered)
      );

      const maxTotalGrain = Math.max(...grain.map(object => object.totalGrain));

      const dateArray = allDates.filter(
        (item, pos) => allDates.indexOf(item) === pos
      );

      return {
        series: [
          generateGrainSeries(
            'Sacas de milho',
            'column',
            1,
            grain,
            'totalAmountOrdered',
            dateArray
          ),
          generateGrainSeries(
            'Sacas de soja',
            'column',
            2,
            grain,
            'totalAmountOrdered',
            dateArray
          ),
          generateGrainSeries(
            'Sacas de sorgo',
            'column',
            3,
            grain,
            'totalAmountOrdered',
            dateArray
          ),
          generateGrainSeries(
            'Contratos de milho',
            'line',
            1,
            grain,
            'totalGrain',
            dateArray
          ),
          generateGrainSeries(
            'Contratos de soja',
            'line',
            2,
            grain,
            'totalGrain',
            dateArray
          ),
          generateGrainSeries(
            'Contratos de sorgo',
            'line',
            3,
            grain,
            'totalGrain',
            dateArray
          ),
        ],
        options: {
          chart: {
            type: 'line',
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: 'Quantidade de sacas e contratos por grão',
            align: 'left',
            style: {
              fontFamily: 'Poppins',
              fontWeight: '500',
            },
          },
          xaxis: {
            type: 'datetime',
            categories: dateArray,
            labels: {
              formatter: val =>
                Number.isNaN(new Date(val))
                  ? val
                  : format(new Date(val), 'dd/MM/yy'),
            },
          },
          colors: [
            '#ffb822',
            '#0abb87',
            '#007bff',
            '#f5c662',
            '#67e0bd',
            '#6eaff5',
          ],
          yaxis: [
            {
              seriesName: 'Sacas de milho',
              max: maxTotalAmount,
              title: {
                text: 'Quantidade de sacas por grão',
              },
            },
            {
              seriesName: 'Sacas de milho',
              show: false,
            },
            {
              seriesName: 'Sacas de milho',
              show: false,
            },
            {
              seriesName: 'Contratos de milho',
              opposite: true,
              max: maxTotalGrain + 1,
              title: {
                text: 'Quantidade de contratos por grão',
              },
            },
            {
              seriesName: 'Contratos de milho',
              opposite: true,
              show: false,
            },
            {
              seriesName: 'Contratos de milho',
              opposite: true,
              show: false,
            },
          ],
          tooltip: {
            y: {
              formatter: val => toInteger(val),
            },
          },
        },
      };
    }
    return {};
  }, [grain]);

  const totalAmountOffer = useMemo(() => {
    if (grainOffer && grainOffer.length > 0) {
      const allDates = grainOffer.map(g =>
        new Date(g._id.year, g._id.month - 1, g._id.dayOfMonth).valueOf()
      );

      const maxTotalAmount = Math.max(
        ...grainOffer.map(object => object.totalAmount)
      );

      const maxTotalGrain = Math.max(
        ...grainOffer.map(object => object.totalGrain)
      );

      const dateArray = allDates.filter(
        (item, pos) => allDates.indexOf(item) === pos
      );

      return {
        series: [
          generateGrainSeries(
            'Sacas de milho',
            'column',
            1,
            grainOffer,
            'totalAmount',
            dateArray
          ),
          generateGrainSeries(
            'Sacas de soja',
            'column',
            2,
            grainOffer,
            'totalAmount',
            dateArray
          ),
          generateGrainSeries(
            'Sacas de sorgo',
            'column',
            3,
            grainOffer,
            'totalAmount',
            dateArray
          ),
          generateGrainSeries(
            'Ofertas de milho',
            'line',
            1,
            grainOffer,
            'totalGrain',
            dateArray
          ),
          generateGrainSeries(
            'Ofertas de soja',
            'line',
            2,
            grainOffer,
            'totalGrain',
            dateArray
          ),
          generateGrainSeries(
            'Ofertas de sorgo',
            'line',
            3,
            grainOffer,
            'totalGrain',
            dateArray
          ),
        ],
        options: {
          chart: {
            type: 'line',
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: 'Quantidade de sacas e ofertas em aberto por grão',
            align: 'left',
            style: {
              fontFamily: 'Poppins',
              fontWeight: '500',
            },
          },
          xaxis: {
            type: 'datetime',
            categories: dateArray,
            labels: {
              formatter: val =>
                Number.isNaN(new Date(val))
                  ? val
                  : format(new Date(val), 'dd/MM/yy'),
            },
          },
          colors: [
            '#ffb822',
            '#0abb87',
            '#007bff',
            '#f5c662',
            '#67e0bd',
            '#6eaff5',
          ],
          yaxis: [
            {
              seriesName: 'Sacas de milho',
              max: maxTotalAmount,
              title: {
                text: 'Quantidade de sacas por grão',
              },
            },
            {
              seriesName: 'Sacas de milho',
              show: false,
            },
            {
              seriesName: 'Sacas de milho',
              show: false,
            },
            {
              seriesName: 'Ofertas de milho',
              opposite: true,
              max: maxTotalGrain + 1,
              title: {
                text: 'Quantidade de ofertas em aberto por grão',
              },
            },
            {
              seriesName: 'Ofertas de milho',
              opposite: true,
              show: false,
            },
            {
              seriesName: 'Ofertas de milho',
              opposite: true,
              show: false,
            },
          ],
          tooltip: {
            y: {
              formatter: val => toInteger(val),
            },
          },
        },
      };
    }
    return {};
  }, [grainOffer]);

  return (
    <>
      <Card style={{ display: 'flex', flexWrap: 'wrap', padding: 16 }}>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <Button
            onClick={() => setFilterByPeriod(!filterByPeriod)}
            color="primary"
          >
            {filterByPeriod
              ? 'Fechar filtro por período'
              : 'Filtrar por período'}
          </Button>
        </Grid>
        {filterByPeriod && (
          <>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <DateRangePicker
                onChange={item => setDateFilter([item.selection])}
                showSelectionPreview
                months={1}
                ranges={dateFilter}
                direction="horizontal"
                locale={pt}
                color="#006837"
                rangeColors={['#006837']}
                dateDisplayFormat="dd/MM/yyyy"
                staticRanges={defaultStaticRanges}
                inputRanges={defaultInputRanges}
                weekStartsOn={0}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Button
                onClick={handleFilter}
                color="primary"
                style={{ marginTop: 12, verticalAlign: 'initial' }}
              >
                Filtrar
              </Button>
            </Grid>
          </>
        )}
      </Card>
      <div className="kt-space-20" />
      <Card style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <OrdersWidget
              value={
                totalContractsPerPeriod
                  ? totalContractsPerPeriod.totalContracts
                  : 0
              }
              label="Quantidade de contratos"
              title="Quantidade de sacas nos contratos"
              priceValue={
                totalContractsPerPeriod
                  ? totalContractsPerPeriod.totalContractPrice
                  : null
              }
              priceTitle="Valor total de contratos"
              grainAmount={
                totalContractsPerPeriod
                  ? totalContractsPerPeriod.totalAmountOrdered
                  : 0
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <OrdersWidget
              value={
                totalOffersPerPeriod ? totalOffersPerPeriod.totalOffers : 0
              }
              label="Quantidade de ofertas em aberto"
              title="Total de sacas nas ofertas em aberto"
              priceValue={
                totalOffersPerPeriod
                  ? totalOffersPerPeriod.totalOffersPrice
                  : null
              }
              priceTitle="Valor total de ofertas em aberto"
              grainAmount={
                totalOffersPerPeriod ? totalOffersPerPeriod.totalAmount : 0
              }
            />
          </Grid>
        </Grid>
      </Card>
      <div className="kt-space-20" />
      <Card style={{ display: 'flex', flexWrap: 'wrap', padding: 16 }}>
        <Grid container spacing={2}>
          {totalContracts.series[0].data.length > 0 ||
          totalContracts.series[1].data.length > 0 ? (
            <>
              <Grid item xs={12} sm={12} lg={6} md={12}>
                <Chart
                  options={totalContracts.options}
                  series={totalContracts.series}
                  type="line"
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={6} md={12}>
                <Chart
                  options={totalContractPrice.options}
                  series={totalContractPrice.series}
                  type="line"
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} lg={6} md={12}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                align="center"
              >
                Não há ofertas em aberto e contratos nesse período
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={12} lg={6} md={12}>
            {totalAmountOffer.series ? (
              <Chart
                options={totalAmountOffer.options}
                series={totalAmountOffer.series}
                type="line"
              />
            ) : (
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                align="center"
              >
                Não há ofertas em aberto nesse período
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={12}>
            {totalAmountOrdered.series ? (
              <Chart
                options={totalAmountOrdered.options}
                series={totalAmountOrdered.series}
                type="line"
              />
            ) : (
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                align="center"
              >
                Não há contratos nesse período
              </Typography>
            )}
          </Grid>
        </Grid>
      </Card>
      <div className="kt-space-20" />
      <Grid container>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <ContractTable contract={contracts} />
        </Grid>
      </Grid>
    </>
  );
}

ContractTable.propTypes = {
  contract: PropTypes.oneOfType([PropTypes.array]),
};

ContractTable.defaultProps = {
  contract: [],
};
