import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LayoutSplashScreen } from '../../../_metronic';

import Builder from './Builder';
import Dashboard from './Dashboard';
import UserFormPage from './users/UserFormPage';
import FreightsPage from './settings/FreightsPage';
import OffersPage from './negotiations/OffersPage';
import ContractsPage from './negotiations/ContractsPage';
import ContractsDetailsPage from './negotiations/ContractsDetailsPage';
import ParametersPage from './settings/ParametersPage';
import TicketsPage from './negotiations/TicketsPage';
import TicketFormPage from './negotiations/TicketFormPage';
import UsersPage from './users/UsersPage';
import TruckTypePage from './settings/TruckTypesPage';
import TruckFormPage from './settings/TruckFormPage';
import TrucksPage from './settings/TrucksPage';

export default function HomePage() {
  const loggedUser = useSelector(state => state.auth.user);
  const { isLogistic, isAdmin } = loggedUser;

  const canSeeAllRoutes = !isLogistic || (isAdmin && isLogistic);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/dashboard" component={Dashboard} />
        <Route exact path="/contracts" component={ContractsPage} />
        <Route exact path="/tickets" component={TicketsPage} />
        <Route exact path="/ticket-form" component={TicketFormPage} />
        <Route
          exact
          path="/contracts-details"
          component={ContractsDetailsPage}
        />
        <Route exact path="/truck-types" component={TruckTypePage} />
        <Route exact path="/truck-form" component={TruckFormPage} />
        <Route exact path="/trucks" component={TrucksPage} />
        {!canSeeAllRoutes && <Redirect to="/error/error-v6" />}
        <Route exact path="/freights" component={FreightsPage} />
        <Route exact path="/parameters" component={ParametersPage} />
        <Route exact path="/user-form" component={UserFormPage} />
        <Route exact path="/offers" component={OffersPage} />
        <Route exact path="/users" component={UsersPage} />
        {/* Example routes */}
        <Route path="/builder" component={Builder} />
        <Redirect to="/error/error-v6" />
      </Switch>
    </Suspense>
  );
}
