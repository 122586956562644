export default {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      {
        title: 'Início',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        page: 'dashboard',
        translate: 'MENU.DASHBOARD',
      },
      { section: 'Negociações', translate: 'MENU.NEGOTIATIONS' },
      {
        title: 'Contratos',
        root: true,
        icon: 'flaticon2-sheet',
        page: 'contracts',
        translate: 'MENU.CONTRACTS',
      },
      {
        title: 'Ofertas',
        root: true,
        icon: 'flaticon2-document',
        page: 'offers',
        translate: 'MENU.OFFERS',
      },
      {
        title: 'Tickets',
        root: true,
        icon: 'flaticon2-list-2',
        page: 'tickets',
        translate: 'MENU.TICKETS_PAGE',
      },
      { section: 'Logística', translate: 'MENU.LOGISTICS' },
      {
        title: 'Caminhões',
        root: true,
        icon: 'flaticon2-delivery-truck',
        page: 'trucks',
        translate: 'MENU.TRUCK_PAGE',
      },
      {
        title: 'Cotações de fretes',
        root: true,
        icon: 'flaticon2-delivery-package',
        page: 'freights',
        translate: 'MENU.FREIGHTS',
      },
      {
        title: 'Tipos de caminhões',
        root: true,
        icon: 'flaticon2-lorry',
        page: 'truck-types',
        translate: 'MENU.TRUCK_TYPES',
      },
      { section: 'Usuários', translate: 'MENU.USERS' },
      {
        title: 'Cadastrar',
        root: true,
        icon: 'flaticon2-user',
        page: 'user-form',
        translate: 'MENU.NEW_USER',
      },
      {
        title: 'Gerenciar',
        root: true,
        icon: 'flaticon2-avatar',
        page: 'users',
        translate: 'MENU.MANAGE_USERS',
      },
      { section: 'Configurações', translate: 'MENU.SETTINGS' },
      {
        title: 'Parâmetros do App',
        root: true,
        icon: 'flaticon2-list',
        page: 'parameters',
        translate: 'MENU.PARAMETERS',
      },
    ],
  },
};
