import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import MaterialTable from 'material-table';
import { Icon, Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  cnpjMask,
  cpfMask,
  localeDecimalNumber,
  tableLocalization,
} from '../../../../_metronic';
import SnackbarContentWrapper from '../../../components/SnackbarContentWrapper';

const AddressTable = ({ addressList, userName, isUserSeller }) => (
  <MaterialTable
    columns={[
      {
        emptyValue: '-',
        field: 'isBuyAddress',
        title: 'Tipo de endereço',
        render: ({ isBuyAddress }) => (isBuyAddress ? 'Compra' : 'Venda'),
      },
      { field: 'name', title: 'Nome' },
      {
        title: 'Cidade',
        render: ({ city }) => {
          const { name, uf } = city;
          return `${name} - ${uf}`;
        },
      },
      {
        emptyValue: '-',
        field: 'productionAreaInHa',
        hidden: !isUserSeller,
        title: 'Área de prod.',
        render: ({ productionAreaInHa }) =>
          `${localeDecimalNumber(productionAreaInHa)} hectares`,
      },
      {
        field: 'dirtRoadKm',
        hidden: true,
        title: 'Estrada de chão',
        render: ({ dirtRoadKm }) => `${localeDecimalNumber(dirtRoadKm)} km`,
      },
      {
        emptyValue: '-',
        field: 'isTaxPaidOnRevenue',
        hidden: !isUserSeller,
        title: 'Funrural',
        render: ({ isTaxPaidOnRevenue }) =>
          isTaxPaidOnRevenue
            ? 'Receita de comercialização'
            : 'Folha de pagamento',
      },
      {
        field: 'mapsLink',
        title: 'Link do Maps',
        render: (
          { mapsLink } // eslint-disable-line react/prop-types
        ) => (
          <a href={mapsLink} target="_blank" rel="noopener noreferrer">
            Acessar
          </a>
        ),
      },
      {
        field: 'location',
        hidden: true,
        title: 'Localização',
        render: ({ location }) =>
          `${location.coordinates[1]}, ${location.coordinates[0]}`,
      },
      {
        field: 'addressRoute',
        title: 'Roteiro',
      },
      {
        field: 'addressNote',
        title: 'Observações gerais',
      },
    ]}
    data={addressList}
    localization={tableLocalization('endereço')}
    options={{
      columnsButton: true,
      padding: 'dense',
      paging: false,
      toolbarButtonAlignment: 'left',
    }}
    title={`Endereços de ${userName}`}
  />
);

const columns = [
  {
    field: 'name',
    title: 'Nome',
    width: null,
  },
  {
    emptyValue: '-',
    field: 'cnpj',
    render: ({ cnpj }) => (cnpj ? cnpjMask(cnpj) : '-'),
    title: 'CNPJ',
  },
  {
    emptyValue: '-',
    field: 'cpf',
    render: ({ cpf }) => (cpf ? cpfMask(cpf) : '-'),
    title: 'CPF',
  },
  {
    emptyValue: '-',
    field: 'email',
    render: ({ email }) => email || '-',
    title: 'Email',
  },
  {
    field: 'phone',
    title: 'Telefone',
  },
  {
    field: 'isActive',
    hidden: true,
    type: 'boolean',
    title: 'Está ativo?',
  },
  {
    field: 'isAdmin',
    hidden: true,
    type: 'boolean',
    title: 'É administrador?',
  },
  {
    field: 'isConsultant',
    hidden: true,
    type: 'boolean',
    title: 'É consultor?',
  },
  {
    field: 'isLogistic',
    hidden: true,
    type: 'boolean',
    title: 'É logístico?',
  },
  {
    field: 'isBuyer',
    type: 'boolean',
    title: 'É comprador?',
  },
  {
    field: 'isSeller',
    type: 'boolean',
    title: 'É produtor?',
  },
  {
    hidden: true,
    emptyValue: '-',
    field: 'harvestNote',
    title: 'Observações sobre colheita',
  },
  {
    hidden: true,
    emptyValue: '-',
    field: 'consultant',
    render: ({ consultant }) => consultant.name || '-',
    title: 'Consultor',
  },
  {
    hidden: true,
    render: ({ stateRegistrations }) =>
      stateRegistrations &&
      (stateRegistrations.length > 1
        ? stateRegistrations.join(', ')
        : stateRegistrations),
    title: 'Inscrição Estadual',
  },
  {
    hidden: true,
    emptyValue: '-',
    field: 'paymentCreditInDays',
    title: 'Dias para pagamento',
    type: 'numeric',
  },
  {
    field: 'isApproved',
    title: 'Cadastro aprovado?',
    type: 'boolean',
  },
  {
    hidden: true,
    field: 'hasScale',
    title: 'Possui balança?',
    type: 'boolean',
  },
];

const UsersPage = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    variant: 'success',
  });

  const handleSnackbar = (
    message = '',
    variant = snackbar.variant || 'success'
  ) => {
    setSnackbar({
      isOpen: !!message,
      message,
      variant,
    });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSnackbar();
  };

  useEffect(() => {
    const loadUsers = async () => {
      setIsLoading(true);

      try {
        const { data: res } = await axios.get('/users');

        if (res.status === 'OK') setUsers(res.data);
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao buscar usuários no servidor.',
          'error'
        );
      }

      setIsLoading(false);
    };

    loadUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        actions={[
          {
            icon: 'edit',
            tooltip: 'Editar usuário',
            onClick: (_, rowData) =>
              history.push('/user-form', { editUser: rowData }),
          },
        ]}
        columns={columns}
        data={users}
        detailPanel={[
          rowData => ({
            disabled: !rowData.addressList.length,
            icon: () => (
              <Icon hidden={!rowData.addressList.length}>directions</Icon>
            ),
            tooltip: rowData.addressList.length && 'Ver endereços',
            render: () => (
              <AddressTable
                addressList={rowData.addressList}
                userName={rowData.name}
                isUserSeller={rowData.isSeller}
              />
            ),
          }),
        ]}
        isLoading={isLoading}
        localization={tableLocalization('usuário')}
        options={{
          columnsButton: true,
          draggable: true,
          pageSize: 15,
          pageSizeOptions: [15, 30, 50, 100],
          showTitle: false,
          toolbarButtonAlignment: 'left',
        }}
        title="Usuários Cadastrados"
      />
      <Snackbar
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        open={snackbar.isOpen}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          message={snackbar.message}
        />
      </Snackbar>
    </div>
  );
};

AddressTable.propTypes = {
  addressList: PropTypes.oneOfType([PropTypes.array]),
  userName: PropTypes.string,
  isUserSeller: PropTypes.bool,
};

AddressTable.defaultProps = {
  addressList: [],
  userName: '',
  isUserSeller: false,
};

export default UsersPage;
