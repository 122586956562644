import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  badge: {
    borderRadius: '50%',
    height: 6,
    width: 6,
    marginTop: 5,
    marginRight: 2,
    backgroundColor: props => {
      return theme.palette[props.statusColor][
        props.statusColor === 'text' ? 'primary' : 'main'
      ];
    },
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default function Status({ isDone, isCanceled, statusString, isBold }) {
  let statusObject;

  if (statusString) {
    statusObject = {
      status: statusString,
      statusColor: statusString === 'Entregue' ? 'text' : 'success',
    };
  } else if (isDone) {
    statusObject = { status: 'Fechada', statusColor: 'text' };
  } else if (isCanceled) {
    statusObject = { status: 'Cancelada', statusColor: 'error' };
  } else {
    statusObject = { status: 'Em aberto', statusColor: 'success' };
  }

  const { status, statusColor } = statusObject;
  const classes = useStyles({ statusColor });

  return (
    <div className={classes.status}>
      <div className={classes.badge} />
      <Typography style={{ fontWeight: isBold ? 600 : 400 }}>
        {status}
      </Typography>
    </div>
  );
}

Status.propTypes = {
  isDone: PropTypes.bool,
  isCanceled: PropTypes.bool,
  statusString: PropTypes.string,
  isBold: PropTypes.bool,
};

Status.defaultProps = {
  isDone: false,
  isCanceled: false,
  statusString: '',
  isBold: false,
};
