import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  Snackbar,
  TextField,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import SnackbarContentWrapper from '../../../components/SnackbarContentWrapper';
import { tableLocalization } from '../../../../_metronic/utils/utils';
import { DecimalInput } from '../../../components/CustomInputs';

const addInputAdornment = (adornment, position = 'end') => ({
  [`${position}Adornment`]: (
    <InputAdornment position={position}>{adornment}</InputAdornment>
  ),
});

const useStyles = makeStyles({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
});

const defaultErrors = {
  axles: '',
  capacitiesMin: '',
  capacitiesMax: '',
  scaleWeight: '',
  vehicle: '',
};

const defaultTouched = {
  axles: false,
  capacitiesMin: false,
  capacitiesMax: false,
  scaleWeight: false,
  vehicle: false,
};

const customInputPropTypes = {
  propTypes: {
    onChange: PropTypes.func.isRequired,
    rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  },
  defaultProps: {
    value: '',
  },
};

export default function TruckTypeFormPage() {
  const user = useSelector(state => state.auth.user);

  const [truckTypes, setTruckTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [touched, setTouched] = useState(defaultTouched);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    variant: 'success',
  });

  const handleSnackbar = (
    message = '',
    variant = snackbar.variant || 'success'
  ) => {
    setSnackbar({
      isOpen: !!message,
      message,
      variant,
    });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSnackbar();
  };

  const handleChange = (field, value, rowData) => {
    const newErrors = {};

    if (!value) {
      newErrors[field] = 'Campo obrigatório.';

      setErrors({ ...errors, ...newErrors });
    } else if (field === 'scaleWeight') {
      newErrors[field] =
        Number(value) <= 0 ? 'O peso deve ser maior do que 0.' : '';

      setErrors({ ...errors, ...newErrors });
    } else if (field === 'axles') {
      newErrors[field] =
        Number(value) <= 0 ? 'O eixo deve ser maior do que 0.' : '';

      setErrors({ ...errors, ...newErrors });
    } else {
      if (field === 'capacitiesMin') {
        if (rowData.capacities && rowData.capacities[1]) {
          newErrors[field] =
            Number(value) >= Number(rowData.capacities[1]) || Number(value) <= 0
              ? 'A capacidade mínima deve ser menor do que a máxima.'
              : '';
        } else {
          newErrors[field] =
            Number(value) <= 0
              ? 'A capacidade  mínima deve ser maior do que 0.'
              : '';
        }
      } else if (field === 'capacitiesMax') {
        if (rowData.capacities && rowData.capacities[0]) {
          newErrors[field] =
            Number(value) <= Number(rowData.capacities[0])
              ? 'A capacidade  máximo deve ser maior do que a mínima.'
              : '';
        } else {
          newErrors[field] =
            Number(value) <= 0
              ? 'A capacidade  máximo deve ser maior do que 0.'
              : '';
        }
      }
      setErrors({ ...defaultErrors, ...newErrors });
    }
    setTouched({ ...defaultTouched, [field]: true });
  };

  const axlesInput = ({ onChange, rowData, value: oldValue }) => (
    <TextField
      autoFocus={touched.axles}
      error={!!errors.axles}
      helperText={errors.axles}
      name="axles"
      onChange={({ target: { value } }) => {
        onChange(value);
        handleChange('axles', value, rowData);
      }}
      required
      value={oldValue}
    />
  );
  axlesInput.propTypes = customInputPropTypes.propTypes;
  axlesInput.defaultProps = customInputPropTypes.defaultProps;

  const capacitiesMinInput = ({ onChange, rowData, value: oldValue }) => (
    <TextField
      autoFocus={touched.capacitiesMin}
      error={!!errors.capacitiesMin}
      helperText={errors.capacitiesMin}
      name="capacitiesMin"
      InputProps={{
        ...addInputAdornment('kg'),
        inputComponent: DecimalInput,
      }}
      onChange={({ target: { value } }) => {
        onChange(value);
        handleChange('capacitiesMin', value, rowData);
      }}
      required
      value={oldValue}
    />
  );
  capacitiesMinInput.propTypes = customInputPropTypes.propTypes;
  capacitiesMinInput.defaultProps = customInputPropTypes.defaultProps;

  const capacitiesMaxInput = ({ onChange, rowData, value: oldValue }) => (
    <TextField
      autoFocus={touched.capacitiesMax}
      error={!!errors.capacitiesMax}
      helperText={errors.capacitiesMax}
      name="capacitiesMax"
      InputProps={{
        ...addInputAdornment('kg'),
        inputComponent: DecimalInput,
      }}
      onChange={({ target: { value } }) => {
        onChange(value);
        handleChange('capacitiesMax', value, rowData);
      }}
      required
      value={oldValue}
    />
  );
  capacitiesMaxInput.propTypes = customInputPropTypes.propTypes;
  capacitiesMaxInput.defaultProps = customInputPropTypes.defaultProps;

  const scaleWeightInput = ({ onChange, rowData, value: oldValue }) => (
    <TextField
      autoFocus={touched.scaleWeight}
      error={!!errors.scaleWeight}
      helperText={errors.scaleWeight}
      name="scaleWeight"
      InputProps={{
        ...addInputAdornment('kg'),
        inputComponent: DecimalInput,
      }}
      onChange={({ target: { value } }) => {
        onChange(value);
        handleChange('scaleWeight', value, rowData);
      }}
      required
      value={oldValue}
    />
  );
  scaleWeightInput.propTypes = customInputPropTypes.propTypes;
  scaleWeightInput.defaultProps = customInputPropTypes.defaultProps;

  const vehicleInput = ({ onChange, rowData, value: oldValue }) => (
    <TextField
      autoFocus={touched.vehicle}
      error={!!errors.vehicle}
      helperText={errors.vehicle}
      name="vehicle"
      onChange={({ target: { value } }) => {
        onChange(value);
        handleChange('vehicle', value, rowData);
      }}
      required
      value={oldValue}
    />
  );
  vehicleInput.propTypes = customInputPropTypes.propTypes;
  vehicleInput.defaultProps = customInputPropTypes.defaultProps;

  const classes = useStyles();

  useEffect(() => {
    const loadFreights = async () => {
      setIsLoading(true);
      try {
        const { data: res } = await axios.get('/trucks/types');
        if (res.status === 'OK') setTruckTypes(res.data);
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao buscar tipos de caminhoes.',
          'error'
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadFreights();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <MaterialTable
          columns={[
            {
              editComponent: vehicleInput,
              field: 'vehicle',
              title: 'Veículo',
              width: null,
            },
            {
              editComponent: axlesInput,
              field: 'axles',
              title: 'Eixos',
              type: 'numeric',
              defaultSort: 'asc',
            },
            {
              editComponent: scaleWeightInput,
              field: 'scaleWeight',
              title: 'Peso de balança (kg)',
              type: 'numeric',
            },
            {
              editComponent: capacitiesMinInput,
              field: 'capacities[0]',
              title: 'Caixote cheio (kg)',
              type: 'numeric',
            },
            {
              editComponent: capacitiesMaxInput,
              field: 'capacities[1]',
              title: 'Caixote cheio (kg)',
              type: 'numeric',
            },
            {
              editable: 'never',
              emptyValue: 'neste momento',
              field: 'updatedAt',
              title: 'Atualizado em',
              type: 'datetime',
            },
            {
              editable: 'never',
              emptyValue: user.name,
              field: 'createdBy.name',
              title: 'Atualizado por',
            },
          ]}
          data={truckTypes}
          editable={{
            onRowAdd: async newTypeTruck => {
              const hasErrors = Object.values(errors).some(e => e !== '');

              if (hasErrors) {
                handleSnackbar(
                  'Erro ao adicionar tipo de caminhão, os valores são inválidos.',
                  'error'
                );
              } else {
                try {
                  newTypeTruck.capacities = Object.values(
                    newTypeTruck.capacities
                  );

                  const { data: res } = await axios.post(
                    `/trucks/types`,
                    newTypeTruck
                  );

                  if (res && res.status === 'OK') {
                    newTypeTruck._id = res.data._id;

                    setTruckTypes([...truckTypes, newTypeTruck]);
                    handleSnackbar(res.message, 'success');
                  }
                } catch (error) {
                  handleSnackbar(
                    (error.response && error.response.data.message) ||
                      'Erro ao adicionar tipo de caminhão.',
                    'error'
                  );
                }

                setTouched(defaultTouched);
              }
            },
            onRowUpdate: async (editedTypeTruck, oldData) => {
              const hasErrors = Object.values(errors).some(e => e !== '');

              if (hasErrors) {
                handleSnackbar(
                  'Erro ao atualizar tipo de caminhão, os valores são inválidos.',
                  'error'
                );
              } else {
                try {
                  editedTypeTruck.capacities = Object.values(
                    editedTypeTruck.capacities
                  );

                  const newTypeTrucks = [...truckTypes];

                  const { data: res } = await axios.patch(
                    `/trucks/types/${oldData._id}`,
                    editedTypeTruck
                  );

                  if (res && res.status === 'OK') {
                    newTypeTrucks[
                      newTypeTrucks.indexOf(oldData)
                    ] = editedTypeTruck;

                    setTruckTypes(newTypeTrucks);
                    handleSnackbar(res.message, 'success');
                  }
                } catch (error) {
                  handleSnackbar(
                    (error.response && error.response.data.message) ||
                      'Erro ao atualizar tipo de caminhão.',
                    'error'
                  );
                }

                setTouched(defaultTouched);
              }
            },
            onRowDelete: async oldData => {
              try {
                const newTypeTrucks = truckTypes.filter(f => f !== oldData);

                const { data: res } = await axios.delete(
                  `/trucks/types/${oldData._id}`
                );

                if (res && res.status === 'OK') {
                  setTruckTypes(newTypeTrucks);
                  handleSnackbar(res.message, 'success');
                }
              } catch (error) {
                handleSnackbar(
                  (error.response && error.response.data.message) ||
                    'Erro ao remover tipo de caminhão.',
                  'error'
                );
              }
            },
          }}
          localization={tableLocalization(
            'tipo de caminhão',
            'tipos de caminhões'
          )}
          options={{
            columnsButton: true,
            exportAllData: true,
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [10, 30, 50, 100],
            showTitle: false,
            toolbarButtonAlignment: 'left',
          }}
          title="Tipos de caminhões"
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        open={snackbar.isOpen}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          message={snackbar.message}
        />
      </Snackbar>
    </>
  );
}
