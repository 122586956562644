import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import axios from 'axios';
import { format, isAfter, addDays, subDays } from 'date-fns';
import { pt } from 'date-fns/esm/locale';
import {
  Card,
  Button,
  Grid,
  makeStyles,
  FormControl,
  Typography,
  CardActionArea,
  CardMedia,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { toAbsoluteUrl } from '../../../../_metronic';
import { getGrain, tableLocalization } from '../../../../_metronic/utils/utils';
import {
  defaultStaticRanges,
  defaultInputRanges,
} from '../../../../_metronic/utils/definedRanges';
import Status from '../../../components/Status';

const emptyOrdersImage = toAbsoluteUrl(
  '/media/illustrations/empty_background.png'
);

const currency = {
  type: 'currency',
  currencySetting: {
    currencyCode: 'BRL',
    locale: 'pt',
    maximumFractionDigits: 3,
    minimumFractionDigits: 2,
  },
};

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    padding: 16,
  },
  cardActionArea: {
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'wrap',
    alignContent: 'flex-start',
    padding: 16,
  },
  cardItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  divider: {
    marginTop: 16,
    marginBottom: 8,
  },
  media: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginBottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonFilter: {
    marginTop: 12,
    verticalAlign: 'initial',
  },
  divButtonDetails: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
    marginBottom: 16,
  },
  buttonDetails: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 8,
  },
  customIsDoneColor: {
    marginTop: 12,
    marginLeft: -8,
    backgroundColor: '#000000',
  },
  customIsNotDoneColor: {
    marginTop: 12,
    marginLeft: -8,
    backgroundColor: '#006837',
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  dialogContent: {
    padding: 16,
  },
  title: {
    marginBottom: 16,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'center',
    height: '100%',
  },
  containerEmptyImage: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  emptyMedia: {
    width: '30%',
  },
  negotiationItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleButtonContainer: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 8,
  },
  contractField: {
    fontWeight: 600,
  },
}));

export default function ContractsPage() {
  const history = useHistory();
  const loggedUser = useSelector(state => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    grain: '',
    isBuying: '',
    isCanceled: '',
    isDone: '',
    createdBy: '',
    startDate: '',
    endDate: '',
  });
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: addDays(new Date(), 30),
      key: 'selection',
    },
  ]);
  const [ascendingOrder, setAscendingOrder] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [params, setParams] = useState({});
  const [filterByPeriod, setFilterByPeriod] = useState(false);

  useEffect(() => {
    const loadOrders = async () => {
      setIsLoading(true);

      try {
        const { data: res } = await axios.get(
          `/transactions/consultant/${loggedUser._id}`,
          {
            params,
          }
        );
        if (res && res.data) {
          setTransactions(res.data);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      } finally {
        setIsLoading(false);
      }
    };

    loadOrders();
  }, [loggedUser, params]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const { data: res } = await axios.get('/users');
        if (res && res.data) {
          setUsers(
            res.data.filter(user => {
              return user.isBuyer || user.isSeller;
            })
          );
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };

    loadUsers();
  }, []);

  const handleFilterChange = (label, value) => {
    if (label === 'dateFilter') {
      setFilters({
        ...filters,
        startDate: value.startDate,
        endDate: value.endDate,
      });
    } else {
      setFilters({ ...filters, [label]: value });
    }
  };

  const handleFilter = () => {
    const filter = !(
      filters.isDone === '' &&
      filters.grain === '' &&
      filters.isCanceled === '' &&
      filters.isBuying === '' &&
      filters.createdBy === '' &&
      filters.startDate === '' &&
      filters.endDate === ''
    );
    setParams({ ...filters, filter });
  };

  // eslint-disable-next-line no-shadow
  const getTransactionsDetails = (transactionsId, offerId, isBuyingOffer) => {
    const loadTickets = async () => {
      const { data: res } = await axios.get(
        `/tickets/transaction/${transactionsId}`
      );

      if (res && res.data)
        history.push('/contracts-details', {
          transactionsId,
          offerId,
          isBuyingOffer,
          tickets: res.data,
        });
    };
    loadTickets();
  };

  const handleSortOrdersBy = (value, isAscendingOrder) => {
    if (value === 'deliveryDeadline' || value === 'createdAt') {
      setTransactions(
        transactions.sort((a, b) => {
          if (isAscendingOrder) {
            return isAfter(new Date(a[value]), new Date(b[value]) ? 1 : -1);
          }
          return isAfter(new Date(b[value]), new Date(a[value]) ? 1 : -1);
        })
      );
    }
    setTransactions(
      transactions.sort((a, b) => {
        if (isAscendingOrder) {
          return String(a[value]).localeCompare(String(b[value]), undefined, {
            numeric: true,
          });
        }
        return String(b[value]).localeCompare(String(a[value]), undefined, {
          numeric: true,
        });
      })
    );

    setSortBy(value);
  };

  const handleOnStatusChange = value => {
    setOrderStatus(value);
    if (value === 'isCanceled') {
      setFilters({ ...filters, isCanceled: true, isDone: '' });
    } else if (value === 'isDone' || value === 'isOpen') {
      setFilters({
        ...filters,
        isCanceled: false,
        isDone: value === 'isDone',
      });
    } else {
      setFilters({ ...filters, isCanceled: '', isDone: '' });
    }
  };

  const getTicketData = arrayTickets => {
    if (
      arrayTickets.length === 0 ||
      !arrayTickets[0].loadingDate ||
      !arrayTickets[0].amount
    )
      return {
        quantityDelivered: 0,
        deliveryStart: '-',
      };

    let amountSum = 0;
    let firstDate = new Date(arrayTickets[0].loadingDate);

    arrayTickets.forEach(value => {
      amountSum += value.amount || 0;
      if (isAfter(firstDate, new Date(value.loadingDate))) {
        firstDate = new Date(value.loadingDate);
      }
    });

    return {
      quantityDelivered: Math.round(amountSum),
      deliveryStart: format(firstDate, 'dd/MM/yyyy'),
    };
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel>Situação</InputLabel>
            <Select
              value={orderStatus}
              onChange={(_, e) => handleOnStatusChange(e.props.value)}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value="isCanceled">Canceladas</MenuItem>
              <MenuItem value="isOpen">Abertas</MenuItem>
              <MenuItem value="isDone">Finalizadas</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Tipo</InputLabel>
            <Select
              value={filters.isBuying}
              onChange={(_, e) => handleFilterChange('isBuying', e.props.value)}
            >
              <MenuItem value="">
                <em>Todas</em>
              </MenuItem>
              <MenuItem value>Compra</MenuItem>
              <MenuItem value={false}>Venda</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Grão</InputLabel>
            <Select
              value={filters.grain}
              onChange={(_, e) => handleFilterChange('grain', e.props.value)}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value={1}>Milho</MenuItem>
              <MenuItem value={2}>Soja</MenuItem>
              <MenuItem value={3}>Sorgo</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Criado por</InputLabel>
            <Select
              value={filters.createdBy}
              onChange={(_, e) =>
                handleFilterChange('createdBy', e.props.value)
              }
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {users.map(user => (
                <MenuItem key={user._id} value={user._id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              onClick={() => setFilterByPeriod(!filterByPeriod)}
              color="primary"
              variant="outlined"
              className={classes.buttonFilter}
            >
              {filterByPeriod
                ? 'Fechar filtro por período'
                : 'Filtrar por período'}
            </Button>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              onClick={handleFilter}
              color="primary"
              variant="outlined"
              className={classes.buttonFilter}
            >
              Filtrar
            </Button>
          </FormControl>
          {filterByPeriod && (
            <>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <DateRangePicker
                  onChange={item => {
                    setDateFilter([item.selection]);
                    handleFilterChange('dateFilter', item.selection);
                  }}
                  showSelectionPreview
                  months={1}
                  ranges={dateFilter}
                  direction="horizontal"
                  locale={pt}
                  color="#006837"
                  rangeColors={['#006837']}
                  dateDisplayFormat="dd/MM/yyyy"
                  staticRanges={defaultStaticRanges}
                  inputRanges={defaultInputRanges}
                  weekStartsOn={0}
                />
              </Grid>
            </>
          )}
        </div>
        {!showTable && (
          <>
            <FormControl className={classes.formControl}>
              <InputLabel>Ordenar por</InputLabel>
              <Select
                value={sortBy}
                onChange={(_, e) =>
                  handleSortOrdersBy(e.props.value, ascendingOrder)
                }
              >
                <MenuItem value="createdAt">Data de criação</MenuItem>
                <MenuItem value="amountOrdered">Quantidade de sacas</MenuItem>
                <MenuItem value="deliveryDeadline">Prazo de entrega</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} disabled={!sortBy}>
              <InputLabel>Em ordem</InputLabel>
              <Select
                value={ascendingOrder}
                onChange={(_, e) => {
                  setAscendingOrder(e.props.value);
                  handleSortOrdersBy(sortBy, e.props.value);
                }}
              >
                <MenuItem value>Crescente</MenuItem>
                <MenuItem value={false}>Decrescente</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        <FormControl className={classes.formControl}>
          <Button
            onClick={() => setShowTable(!showTable)}
            color="primary"
            variant="outlined"
            className={classes.buttonFilter}
          >
            Alternar exibição
          </Button>
        </FormControl>
      </Card>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {!transactions.length ? (
            <div className={classes.containerEmptyImage}>
              <CardMedia
                className={classes.emptyMedia}
                component="img"
                image={emptyOrdersImage}
              />
              <Typography gutterBottom variant="h6" component="h2">
                Nenhuma negociação encontrada.
              </Typography>
            </div>
          ) : (
            <>
              {showTable ? (
                <MaterialTable
                  columns={[
                    {
                      // eslint-disable-next-line no-shadow
                      render: ({ tickets }) => {
                        if (tickets && tickets.length > 0) {
                          return (
                            tickets.length > 0 &&
                            (tickets.length > 1
                              ? tickets.map(value => value.ticket).join(', ')
                              : tickets[0].ticket)
                          );
                        }
                        return '-';
                      },
                      title: 'Tickets',
                      width: null,
                    },
                    {
                      title: 'Grão',
                      render: ({ grain }) => getGrain(grain).grain,
                    },
                    {
                      field: 'createdAt',
                      title: 'Contrato criado em',
                      type: 'datetime',
                    },
                    {
                      field: 'seller',
                      title: 'Vendedor',
                    },
                    {
                      field: 'buyer',
                      title: 'Comprador',
                    },
                    {
                      field: 'amountOrdered',
                      title: 'Quantidade de sacas',
                      type: 'numeric',
                    },
                    {
                      field: 'finalPrice',
                      title: 'Preço da nota',
                      type: 'currency',
                      ...currency,
                    },
                    {
                      hidden: !loggedUser.isAdmin,
                      field: 'sellerPrice',
                      title: 'Preço do vendedor',
                      type: 'currency',
                      ...currency,
                    },
                    {
                      hidden: !loggedUser.isAdmin,
                      field: 'freightPrice',
                      title: 'Frete',
                      type: 'currency',
                      ...currency,
                    },
                    {
                      hidden: !loggedUser.isAdmin,
                      field: 'taxes',
                      title: 'Imposto',
                      type: 'numeric',
                    },
                    {
                      hidden: !loggedUser.isAdmin,
                      field: 'foxFee',
                      title: 'Taxa FOX',
                      type: 'currency',
                      ...currency,
                    },
                    {
                      hidden: !loggedUser.isAdmin,
                      field: 'buyerPrice',
                      title: 'Preço do comprador',
                      type: 'currency',
                      ...currency,
                    },
                    {
                      field: 'originLink',
                      title: 'Local de coleta',
                      render: (
                        { originLink, origin } // eslint-disable-line react/prop-types
                      ) => (
                        <a
                          href={originLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {origin}
                        </a>
                      ),
                    },
                    {
                      field: 'destinationLink',
                      title: 'Local de entrega',
                      render: (
                        { destinationLink, destination } // eslint-disable-line react/prop-types
                      ) => (
                        <a
                          href={destinationLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {destination}
                        </a>
                      ),
                    },
                    {
                      hidden: !loggedUser.isAdmin,
                      defaultSort: 'asc',
                      field: 'deliveryDeadline',
                      title: 'Prazo de entrega até',
                      type: 'date',
                    },
                    {
                      hidden: !loggedUser.isAdmin,
                      field: 'payableUntil',
                      title: 'Pagamento até',
                      type: 'date',
                    },
                    {
                      title: 'Quantidade já entregue (sacas)',
                      type: 'numeric',
                      // eslint-disable-next-line no-shadow
                      render: ({ tickets }) => {
                        const { quantityDelivered } = getTicketData(tickets);
                        return quantityDelivered;
                      },
                    },
                    {
                      title: 'Quantidade à entregar (sacas)',
                      type: 'numeric',
                      // eslint-disable-next-line no-shadow
                      render: ({ tickets, amountOrdered }) => {
                        const { quantityDelivered } = getTicketData(tickets);
                        return amountOrdered - quantityDelivered;
                      },
                    },
                    {
                      title: 'Data de inicio da entrega',
                      // eslint-disable-next-line no-shadow
                      render: ({ tickets }) => {
                        const { deliveryStart } = getTicketData(tickets);
                        return deliveryStart;
                      },
                    },
                  ]}
                  data={transactions}
                  options={{
                    padding: 'dense',
                    paging: false,
                    exportButton: true,
                    exportFileName: 'Contratos',
                    columnsButton: !!loggedUser.isAdmin,
                    toolbarButtonAlignment: 'left',
                  }}
                  localization={tableLocalization('negociação')}
                  title=""
                  actions={[
                    {
                      icon: 'add',
                      tooltip: 'Abrir ticket',
                      onClick: (_, rowData) =>
                        history.push('/ticket-form', {
                          contractId: rowData._id,
                        }),
                    },
                    {
                      icon: 'more_horiz-horizontal',
                      tooltip: 'Ver detalhes da negociação',
                      onClick: (_, rowData) =>
                        getTransactionsDetails(
                          rowData._id,
                          rowData.offerId,
                          rowData.isBuyingOffer
                        ),
                    },
                  ]}
                />
              ) : (
                <Grid container spacing={2}>
                  {transactions.map(transaction => {
                    const { grain, image } = getGrain(transaction.grain);

                    return (
                      <Grid
                        key={transaction._id}
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        md={6}
                        zeroMinWidth
                      >
                        <Card
                          onClick={() =>
                            getTransactionsDetails(
                              transaction._id,
                              transaction.offerId,
                              transaction.isBuyingOffer
                            )
                          }
                        >
                          <CardActionArea className={classes.cardActionArea}>
                            <div className={classes.cardItem}>
                              <CardMedia
                                component="img"
                                className={classes.media}
                                image={image}
                              />
                              <div className={classes.negotiationItem}>
                                <Typography>Grão</Typography>
                                <Typography
                                  align="left"
                                  gutterBottom
                                  className={classes.contractField}
                                >
                                  {grain}
                                </Typography>
                              </div>
                              <div className={classes.negotiationItem}>
                                <Typography>Quantidade</Typography>
                                <Typography
                                  align="left"
                                  gutterBottom
                                  className={classes.contractField}
                                >
                                  {transaction.amountOrdered} sacas
                                </Typography>
                              </div>
                            </div>
                            <div className={classes.cardItem}>
                              <div className={classes.negotiationItem}>
                                <Typography>Origem</Typography>
                                <Typography
                                  align="left"
                                  gutterBottom
                                  className={classes.contractField}
                                >
                                  {transaction.origin}
                                </Typography>
                              </div>
                            </div>
                            <div className={classes.cardItem}>
                              <div className={classes.negotiationItem}>
                                <Typography>Destino</Typography>
                                <Typography
                                  align="left"
                                  gutterBottom
                                  className={classes.contractField}
                                >
                                  {transaction.destination}
                                </Typography>
                              </div>
                            </div>
                            <div className={classes.cardItem}>
                              <div className={classes.negotiationItem}>
                                <Typography>Status</Typography>
                                <Status isDone={transaction.isDone} isBold />
                              </div>
                              <div className={classes.negotiationItem}>
                                <Typography>Entregue até</Typography>
                                <Typography
                                  align="left"
                                  gutterBottom
                                  className={classes.contractField}
                                >
                                  {format(
                                    new Date(transaction.deliveryDeadline),
                                    'dd/MM/yyyy'
                                  )}
                                </Typography>
                              </div>
                            </div>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
