import React from 'react';

import UserProfile from '../../../app/partials/layout/UserProfile';

export default function Topbar() {
  return (
    <div className="kt-header__topbar">
      <UserProfile showAvatar showHi showBadge={false} />
    </div>
  );
}
