import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import MaterialTable from 'material-table';
import {
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
  Grid,
  Card,
  FormControl,
  CardActionArea,
  Select,
  InputLabel,
  Button,
  MenuItem,
  CardMedia,
} from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { InsertInvitation, LocalShipping } from '@material-ui/icons';
import { format, isAfter, addDays, subDays } from 'date-fns';
import { pt } from 'date-fns/esm/locale';
import {
  tableLocalization,
  toAbsoluteUrl,
} from '../../../../_metronic/utils/utils';
import {
  defaultStaticRanges,
  defaultInputRanges,
} from '../../../../_metronic/utils/definedRanges';
import Status from '../../../components/Status';

const emptyTicketsImage = toAbsoluteUrl('/media/illustrations/no_tickets.png');

const useStyles = makeStyles(theme => ({
  cardSessionTitle: {
    color: '#434349',
    fontWeight: 600,
  },
  cardData: {
    fontWeight: 600,
    marginBottom: 0,
  },
  buttonFilter: {
    marginTop: 12,
    verticalAlign: 'initial',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  dialogContent: {
    padding: 16,
  },
  dividerBottom: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  title: {
    marginBottom: 16,
  },
  textField: {
    marginTop: 0,
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    padding: 16,
  },
  iconArea: {
    marginRight: 2,
  },
  icon: {
    width: 12,
  },
  media: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  containerEmptyImage: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  emptyMedia: {
    width: '30%',
  },
}));

export default function TicketsPage() {
  const history = useHistory();
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [sortBy, setSortBy] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    licensePlate: '',
    createdBy: '',
    wasCreatedBySeller: '',
    startDate: '',
    endDate: '',
  });
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: addDays(new Date(), 30),
      key: 'selection',
    },
  ]);
  const [params, setParams] = useState({});
  const [licensePlate, setLicensePlate] = useState([]);
  const [users, setUsers] = useState([]);
  const [filterByPeriod, setFilterByPeriod] = useState(false);

  useEffect(() => {
    const loadTickets = async () => {
      setIsLoading(true);

      try {
        const { data: res } = await axios.get('/tickets', {
          params,
        });
        if (res && res.data) {
          setTickets(res.data);
          const licensePlateArray = res.data.map(value => {
            return value.licensePlate;
          });
          setLicensePlate(
            licensePlateArray.filter((value, index) => {
              return value && licensePlateArray.indexOf(value) === index;
            })
          );
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      } finally {
        setIsLoading(false);
      }
    };

    loadTickets();
  }, [params]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const { data: res } = await axios.get('/users');
        if (res && res.data) {
          setUsers(
            res.data.filter(user => {
              return user.isBuyer || user.isSeller;
            })
          );
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };

    loadUsers();
  }, []);

  const handleSortTicketBy = value => {
    if (value === 'loadingDate') {
      setTickets(
        tickets.sort((a, b) =>
          isAfter(new Date(a[value]), new Date(b[value])) ? 1 : -1
        )
      );
    } else {
      setTickets(
        tickets.sort((a, b) => String(a[value]).localeCompare(String(b[value])))
      );
    }
    setSortBy(value);
  };

  const handleFilterChange = (label, value) => {
    if (label === 'dateFilter') {
      setFilters({
        ...filters,
        startDate: value.startDate,
        endDate: value.endDate,
      });
    } else {
      setFilters({ ...filters, [label]: value });
    }
  };

  const handleFilter = () => {
    const filter = !(
      filters.status === '' &&
      filters.licensePlate === '' &&
      filters.createdBy === '' &&
      filters.wasCreatedBySeller === '' &&
      filters.startDate === '' &&
      filters.endDate === ''
    );
    setParams({ ...filters, filter });
  };

  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel>Situação</InputLabel>
            <Select
              value={filters.status}
              onChange={(_, e) => handleFilterChange('status', e.props.value)}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value="Aguardando carregamento">
                Aguardando carregamento
              </MenuItem>
              <MenuItem value="Carregando">Carregando</MenuItem>
              <MenuItem value="Entregue">Entregue</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Placa</InputLabel>
            <Select
              value={filters.licensePlate}
              onChange={(_, e) =>
                handleFilterChange('licensePlate', e.props.value)
              }
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {licensePlate.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Usuário</InputLabel>
            <Select
              value={filters.createdBy}
              onChange={(_, e) =>
                handleFilterChange('createdBy', e.props.value)
              }
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {users.map(user => (
                <MenuItem key={user._id} value={user._id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Tipo</InputLabel>
            <Select
              value={filters.wasCreatedBySeller}
              onChange={(_, e) =>
                handleFilterChange('wasCreatedBySeller', e.props.value)
              }
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              <MenuItem value>Novos tickets criados por produtores</MenuItem>
              <MenuItem value={false}>Tickets em andamento</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              onClick={() => setFilterByPeriod(!filterByPeriod)}
              color="primary"
              variant="outlined"
              className={classes.buttonFilter}
            >
              {filterByPeriod
                ? 'Fechar filtro por período'
                : 'Filtrar por período'}
            </Button>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button
              onClick={handleFilter}
              color="primary"
              variant="outlined"
              className={classes.buttonFilter}
            >
              Filtrar
            </Button>
          </FormControl>
          {filterByPeriod && (
            <>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <DateRangePicker
                  onChange={item => {
                    setDateFilter([item.selection]);
                    handleFilterChange('dateFilter', item.selection);
                  }}
                  showSelectionPreview
                  months={1}
                  ranges={dateFilter}
                  direction="horizontal"
                  locale={pt}
                  color="#006837"
                  rangeColors={['#006837']}
                  dateDisplayFormat="dd/MM/yyyy"
                  staticRanges={defaultStaticRanges}
                  inputRanges={defaultInputRanges}
                  weekStartsOn={0}
                />
              </Grid>
            </>
          )}
        </div>
        {!showTable && (
          <FormControl className={classes.formControl}>
            <InputLabel>Ordenar por</InputLabel>
            <Select
              value={sortBy}
              onChange={(_, e) => handleSortTicketBy(e.props.value)}
            >
              <MenuItem value="loadingDate">Data de carregamento</MenuItem>
              <MenuItem value="status">Situação</MenuItem>
              <MenuItem value="licensePlate">Placa</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormControl className={classes.formControl}>
          <Button
            onClick={() => setShowTable(!showTable)}
            color="primary"
            variant="outlined"
            className={classes.buttonFilter}
          >
            Alternar exibição
          </Button>
        </FormControl>
      </Card>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {!tickets.length ? (
            <div className={classes.containerEmptyImage}>
              <CardMedia
                className={classes.emptyMedia}
                component="img"
                image={emptyTicketsImage}
              />
              <Typography gutterBottom variant="h6" component="h2">
                Nenhum ticket encontrado.
              </Typography>
            </div>
          ) : (
            <>
              {showTable ? (
                <MaterialTable
                  columns={[
                    {
                      field: 'ticket',
                      title: 'Número do ticket',
                      width: null,
                      defaultSort: 'desc',
                    },
                    {
                      field: 'loadingDate',
                      title: 'Previsão de carregamento',
                      type: 'datetime',
                      emptyValue: '-',
                    },
                    {
                      field: 'truck.plate',
                      title: 'Placa do caminhão',
                      emptyValue: '-',
                    },
                    {
                      field: 'status',
                      title: 'Situação',
                    },
                  ]}
                  data={tickets}
                  options={{
                    padding: 'dense',
                    paging: false,
                    exportButton: true,
                    exportFileName: 'Tickets',
                    columnsButton: true,
                    toolbarButtonAlignment: 'left',
                  }}
                  localization={tableLocalization('ticket')}
                  title=""
                  actions={[
                    {
                      icon: 'create',
                      tooltip: 'Editar ticket',
                      onClick: (_, rowData) => {
                        return history.push('/ticket-form', {
                          editTicket: rowData,
                          contractId: rowData.transaction._id,
                        });
                      },
                    },
                  ]}
                />
              ) : (
                <>
                  <Grid container spacing={2}>
                    <>
                      {tickets.find(element => !element.loadingDate) && (
                        <Grid item xs={12} md={12}>
                          <Typography
                            component="h2"
                            variant="h5"
                            className={classes.cardSessionTitle}
                          >
                            Novos tickets criados por produtores
                          </Typography>
                        </Grid>
                      )}
                    </>
                    {tickets.map(ticket => {
                      return (
                        !ticket.loadingDate &&
                        !ticket.licensePlate && (
                          <Grid
                            key={ticket._id}
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            xl={3}
                            zeroMinWidth
                          >
                            <Card
                              onClick={() =>
                                history.push('/ticket-form', {
                                  editTicket: ticket,
                                  contractId: ticket.transaction._id,
                                })
                              }
                            >
                              <CardActionArea
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  padding: 16,
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    marginLeft: 8,
                                  }}
                                >
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    className={classes.cardData}
                                  >
                                    Ticket {ticket.ticket}
                                  </Typography>
                                  <Typography>Criado em</Typography>
                                  <Typography
                                    align="left"
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    className={classes.cardData}
                                  >
                                    {format(
                                      new Date(String(ticket.createdAt)),
                                      'dd/MM/yyyy'
                                    )}
                                  </Typography>
                                  <Divider className={classes.dividerBottom} />
                                  <Status statusString={ticket.status} />
                                </div>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        )
                      );
                    })}
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: 20 }}>
                    {tickets.find(element => element.loadingDate) && (
                      <Grid item xs={12} md={12}>
                        <Typography
                          component="h2"
                          variant="h5"
                          className={classes.cardSessionTitle}
                        >
                          Tickets em andamento
                        </Typography>
                      </Grid>
                    )}
                    {tickets.map(ticket => {
                      return (
                        ticket.loadingDate &&
                        ticket.licensePlate && (
                          <Grid
                            key={ticket._id}
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            xl={3}
                            zeroMinWidth
                          >
                            <Card
                              onClick={() =>
                                history.push('/ticket-form', {
                                  editTicket: ticket,
                                  contractId: ticket.transaction._id,
                                })
                              }
                            >
                              <CardActionArea
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  padding: 16,
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    marginLeft: 8,
                                  }}
                                >
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    className={classes.cardData}
                                  >
                                    Ticket {ticket.ticket}
                                  </Typography>
                                  <Typography noWrap component="div">
                                    <Typography
                                      display="inline"
                                      className={classes.iconArea}
                                    >
                                      <InsertInvitation
                                        color="action"
                                        className={classes.icon}
                                      />
                                    </Typography>
                                    Previsão de carregamento
                                  </Typography>
                                  <Typography
                                    align="center"
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    className={classes.cardData}
                                  >
                                    {format(
                                      new Date(String(ticket.loadingDate)),
                                      "dd/MM/yyyy 'às' HH:mm"
                                    )}
                                  </Typography>
                                  <Typography noWrap component="div">
                                    <Typography
                                      display="inline"
                                      className={classes.iconArea}
                                    >
                                      <LocalShipping
                                        color="action"
                                        className={classes.icon}
                                      />
                                    </Typography>
                                    Placa do caminhão
                                  </Typography>
                                  <Typography
                                    align="center"
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    className={classes.cardData}
                                  >
                                    {ticket.licensePlate}
                                  </Typography>
                                  <Divider className={classes.dividerBottom} />
                                  <Status statusString={ticket.status} />
                                </div>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        )
                      );
                    })}
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
