import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Snackbar,
  Paper,
  makeStyles,
  Typography,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import SnackbarContentWrapper from '../../../components/SnackbarContentWrapper';
import {
  cpfMask,
  phoneMask,
  validateCpf,
  validateLicensePlate,
  licensePlateMask,
} from '../../../../_metronic/utils/utils';

const addInputAdornment = (adornment, position = 'end') => ({
  [`${position}Adornment`]: (
    <InputAdornment position={position}>{adornment}</InputAdornment>
  ),
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: 24,
  },
  bottomButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  bottomButton: {
    margin: theme.spacing(0, 1),
  },
  title: {
    marginBottom: 16,
  },
  formControl: {
    marginBottom: 8,
    marginTop: 16,
    width: '100%',
  },
  helperText: {
    marginTop: 0,
  },
}));

export default function TruckFormPage({ location }) {
  const history = useHistory();
  const [truckTypes, setTruckTypes] = useState([]);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = isAutocompleteOpen && options.length === 0;
  const [fieldTruckType, setFieldTruckType] = useState({
    capacitiesMax: '',
    scaleWeight: '',
  });
  const [users, setUsers] = useState([]);
  const [isEditingTicket, setIsEditingTicket] = useState(false);

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    variant: 'success',
  });

  const handleSnackbar = (
    message = '',
    variant = snackbar.variant || 'success'
  ) => {
    setSnackbar({
      isOpen: !!message,
      message,
      variant,
    });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSnackbar();
  };

  const {
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    setValues,
    touched,
    values,
  } = useFormik({
    initialValues: {
      cpf: '',
      createdBy: '',
      driver: '',
      email: '',
      phone: '',
      plate: '',
      referral: '',
      type: '',
      updatedBy: '',
      whatsapp: '',
    },

    validationSchema: Yup.object({
      cpf: Yup.string()
        .required()
        .test('is-cpf-valid', 'CPF inválido.', validateCpf),
      driver: Yup.string().required(),
      email: Yup.string()
        .lowercase()
        .trim()
        .email('Email inválido.')
        .required(),
      phone: Yup.string().min(14, 'Número de telefone inválido.'),
      plate: Yup.string()
        .required()
        .test(
          'is-license-plate-valid',
          'Placa inválida.',
          validateLicensePlate
        ),
      referral: Yup.string(),
      type: Yup.string().required(),
      whatsapp: Yup.string()
        .min(14, 'Número de celular inválido.')
        .required(),
    }),

    onSubmit: async formValues => {
      try {
        let res;

        if (formValues.referral) formValues.referral = formValues.referral._id;

        if (formValues._id) {
          const { data } = await axios.patch(
            `/trucks/${formValues._id}`,
            formValues
          );
          res = data;
        } else {
          const { data } = await axios.post('/trucks/', formValues);
          res = data;
        }

        if (res.status === 'OK') {
          handleSnackbar(res.message, 'success');
          setTimeout(() => {
            history.push('/trucks');
          }, 3250);
        }
      } catch (error) {
        formValues.responsible = '';
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao cadastrar caminhão.',
          'error'
        );
      }
    },
  });

  const handleFormSubmition = async () => {
    const fields = Object.keys(values);
    const isFormValid = !Object.keys(errors).length;

    fields.forEach(f => setFieldTouched(f, true));

    if (isFormValid) {
      handleSubmit();
    }
  };

  const classes = useStyles();

  const handleSelectTruckType = truckTypeId => {
    const truckTypesSelect = truckTypes.find(
      value => value._id === truckTypeId
    );

    setFieldValue('type', truckTypeId);
    setFieldTruckType({
      scaleWeight: truckTypesSelect.scaleWeight,
      capacitiesMax: truckTypesSelect.capacities[1],
    });
  };

  useEffect(() => {
    const loadTrucksTypes = async () => {
      try {
        const { data: res } = await axios.get('/trucks/types');
        if (res && res.status === 'OK') {
          setTruckTypes(res.data);
        }
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao buscar tipos de caminhões',
          'error'
        );
      }
    };

    loadTrucksTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const { data: res } = await axios.get('/users');
        if (res && res.data) {
          setUsers(res.data);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    };

    loadUsers();
  }, []);

  useEffect(() => {
    const loadTruck = async () => {
      if (location.state) {
        setIsEditingTicket(true);
        const { editTruck } = location.state;
        const truck = {
          ...editTruck,
          cpf: editTruck.cpf ? cpfMask(editTruck.cpf) : '',
          driver: editTruck.driver || '',
          email: editTruck.email || '',
          phone: editTruck.phone ? phoneMask(editTruck.phone) : '',
          plate: editTruck.plate ? licensePlateMask(editTruck.plate) : '',
          referral: editTruck.referral ? editTruck.referral : '',
          type: editTruck.type ? editTruck.type._id : '',
          whatsapp: editTruck.whatsapp ? phoneMask(editTruck.whatsapp) : '',
        };

        setFieldTruckType({
          scaleWeight: editTruck.type ? editTruck.type.scaleWeight : '',
          capacitiesMax: editTruck.type ? editTruck.type.capacities[1] : '',
        });
        setValues(truck, false);
      }
    };

    loadTruck();
  }, [location, setValues]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (() => {
      if (active) {
        setOptions(users);
      }
    })();

    return () => {
      active = false;
    };
  }, [users, loading]);

  useEffect(() => {
    if (!isAutocompleteOpen) setOptions([]);
  }, [isAutocompleteOpen]);

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.title}>
          <Typography component="h3" variant="h5">
            {isEditingTicket ? 'Editar caminhão' : 'Cadastrar caminhão'}
          </Typography>
          <FormHelperText>Campos com * são obrigatórios.</FormHelperText>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md xs={12}>
              <TextField
                error={touched.driver && !!errors.driver}
                helperText={touched.driver && errors.driver}
                label="Nome do motorista"
                margin="normal"
                value={values.driver}
                name="driver"
                required
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md xs={12}>
              <TextField
                error={touched.cpf && !!errors.cpf}
                helperText={touched.cpf && errors.cpf}
                label="CPF"
                margin="normal"
                value={values.cpf}
                name="cpf"
                required
                onBlur={handleBlur}
                onChange={e => setFieldValue('cpf', cpfMask(e.target.value))}
              />
            </Grid>
            <Grid item md xs={12}>
              <TextField
                error={touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                label="Telefone"
                margin="normal"
                value={values.phone}
                name="phone"
                onBlur={handleBlur}
                onChange={e =>
                  setFieldValue('phone', phoneMask(e.target.value))
                }
              />
            </Grid>
            <Grid item md xs={12}>
              <TextField
                error={touched.whatsapp && !!errors.whatsapp}
                helperText={touched.whatsapp && errors.whatsapp}
                label="WhatsApp"
                margin="normal"
                value={values.whatsapp}
                name="whatsapp"
                required
                onBlur={handleBlur}
                onChange={e =>
                  setFieldValue('whatsapp', phoneMask(e.target.value))
                }
              />
            </Grid>
            <Grid item md xs={12}>
              <TextField
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                label="Email"
                margin="normal"
                value={values.email}
                name="email"
                required
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md xs={12}>
              <TextField
                error={touched.plate && !!errors.plate}
                helperText={touched.plate && errors.plate}
                label="Placa"
                margin="normal"
                value={values.plate}
                name="plate"
                required
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('plate', licensePlateMask(e.target.value));
                }}
              />
            </Grid>
            <Grid item md xs={12}>
              <FormControl
                className={classes.formControl}
                error={touched.type && !!errors.type}
                required
              >
                <InputLabel>Tipo de máquina</InputLabel>
                <Select
                  value={values.type}
                  onChange={(_, e) => handleSelectTruckType(e.props.value)}
                  style={{ width: '100%' }}
                >
                  {truckTypes.map(truck => (
                    <MenuItem key={truck._id} value={truck._id}>
                      {`${truck.vehicle} (${truck.axles} eixos)`}
                    </MenuItem>
                  ))}
                </Select>
                {touched.type && !!errors.type && (
                  <FormHelperText className={classes.helperText}>
                    {errors.type}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md xs={12}>
              <TextField
                label="Peso de Balança"
                margin="normal"
                value={fieldTruckType.scaleWeight}
                InputProps={{
                  ...addInputAdornment('kg'),
                }}
                name="scaleWeight"
                disabled
              />
            </Grid>
            <Grid item md xs={12}>
              <TextField
                label="Capacidade máx."
                margin="normal"
                value={fieldTruckType.capacitiesMax}
                InputProps={{
                  ...addInputAdornment('kg'),
                }}
                name="capacitiesMax"
                disabled
              />
            </Grid>
            <Grid item md xs={12}>
              <Autocomplete
                className={classes.formControl}
                autoHighlight
                blurOnSelect="touch"
                filterOptions={createFilterOptions({
                  limit: 10,
                  matchFrom: 'start',
                  stringify: user => user.name,
                })}
                getOptionLabel={({ name }) => name || ''}
                getOptionSelected={(user, value) => user._id === value._id}
                id="referral"
                loading={loading}
                onBlur={handleBlur}
                onChange={(_, value) => {
                  setFieldValue('referral', value);
                }}
                onClose={() => setIsAutocompleteOpen(false)}
                onOpen={() => setIsAutocompleteOpen(true)}
                open={isAutocompleteOpen}
                options={options}
                value={values.referral}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    label="Indicação"
                    value={values.referral}
                  />
                )}
              />
            </Grid>
          </Grid>
          <div className={classes.bottomButtonsContainer}>
            <Button
              className={classes.bottomButton}
              color="primary"
              disabled={isSubmitting}
              onClick={handleFormSubmition}
              type="button"
              variant="contained"
            >
              {isEditingTicket ? 'Salvar alterações' : 'Cadastrar'}
            </Button>
          </div>
        </form>
        <Snackbar
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          open={snackbar.isOpen}
        >
          <SnackbarContentWrapper
            onClose={handleSnackbarClose}
            variant={snackbar.variant}
            message={snackbar.message}
          />
        </Snackbar>
      </Paper>
    </>
  );
}

TruckFormPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
};

TruckFormPage.defaultProps = {
  location: {},
};
