import axios from 'axios';

export const LOGIN_URL = '/users/login';
export const REGISTER_URL = '/users/signup';
export const REQUEST_PASSWORD_URL = '/auth/forgot-password';

export const ME_URL = 'api/me';

export function login(userDocument, password) {
  return axios.post(LOGIN_URL, { login: userDocument, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
