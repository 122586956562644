import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';

import * as builder from '../../ducks/builder';

const Footer = ({ footerClasses, footerContainerClasses }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div
      className={`kt-footer ${footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
      id="kt_footer"
    >
      <div className={`kt-container ${footerContainerClasses}`}>
        <div className="kt-footer__copyright">
          {currentYear.toString()}&nbsp;&copy;&nbsp;
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="kt-link"
          >
            FOX Digital Commodities
          </a>
        </div>
        <div className="kt-footer__menu">
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="kt-footer__menu-link kt-link"
          >
            Contato
          </a>
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="kt-footer__menu-link kt-link"
          >
            Sobre
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
