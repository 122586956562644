import React, { useState } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as auth from '../../store/ducks/auth.duck';
import { login as authLogin } from '../../crud/auth.crud';
import { cpfMask, cnpjMask } from '../../../_metronic/utils/utils';

const Login = ({ intl, login }) => {
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{
            userDocument: '',
            password: '',
          }}
          validate={values => {
            const errors = {};

            if (!values.userDocument) {
              errors.userDocument = intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD',
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD',
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
              authLogin(values.userDocument, values.password)
                .then(({ data: res }) => {
                  const user = res.data;

                  if (user.isAdmin || user.isConsultant || user.isLogistic) {
                    login(res.data);
                  } else {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: 'AUTH.VALIDATION.NOT_AUTHORIZED',
                      })
                    );
                  }
                })
                .catch(() => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage({
                      id: 'AUTH.VALIDATION.INVALID_LOGIN',
                    })
                  );
                });
            }, 1000);
          }}
        >
          {({
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            status,
            touched,
            values,
          }) => (
            <form
              noValidate
              autoComplete="off"
              className="kt-form"
              onSubmit={handleSubmit}
            >
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group">
                <TextField
                  className="kt-width-full"
                  error={touched.userDocument && !!errors.userDocument}
                  helperText={touched.userDocument && errors.userDocument}
                  label={intl.formatMessage({
                    id: 'AUTH.INPUT.CNPJCPF',
                  })}
                  margin="normal"
                  name="userDocument"
                  onBlur={handleBlur}
                  onChange={({ target: doc }) =>
                    setFieldValue(
                      'userDocument',
                      doc.value.length <= 14
                        ? cpfMask(doc.value)
                        : cnpjMask(doc.value)
                    )
                  }
                  required
                  value={values.userDocument}
                />
              </div>

              <div className="form-group">
                <TextField
                  className="kt-width-full"
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  label={intl.formatMessage({
                    id: 'AUTH.INPUT.PASSWORD',
                  })}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  type="password"
                  value={values.password}
                />
              </div>

              <div className="kt-login__actions">
                <div />
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isSubmitting}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

Login.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  login: PropTypes.func.isRequired,
};

export default injectIntl(connect(null, auth.actions)(Login));
