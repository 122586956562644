import React from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../_metronic/utils/utils';

export default function OrdersWidget({
  value,
  label,
  title,
  priceValue,
  pricelLabel,
  priceTitle,
  grainAmount,
}) {
  return (
    <div className="kt-widget1">
      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">{title}</h3>
          <span className="kt-widget1__desc">{label}</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            color: '#006837',
            textAlign: 'end',
          }}
        >
          <span className="kt-widget1__number kt-font-brand">
            {grainAmount}
          </span>
          <span className="kt-widget1__desc" style={{ fontWeight: 600 }}>
            {value}
          </span>
        </div>
      </div>

      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">{priceTitle}</h3>
          <span className="kt-widget1__desc">{pricelLabel}</span>
        </div>
        <span className="kt-widget1__number kt-font-success">
          {formatPrice(priceValue)}
        </span>
      </div>
    </div>
  );
}

OrdersWidget.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  title: PropTypes.string,
  priceValue: PropTypes.number,
  priceTitle: PropTypes.string,
  grainAmount: PropTypes.number,
};

OrdersWidget.defaultProps = {
  value: null,
  label: '',
  title: '',
  priceValue: null,
  priceTitle: '',
  grainAmount: null,
};
