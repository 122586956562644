import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Snackbar,
} from '@material-ui/core';
import * as Yup from 'yup';

import { DecimalInput } from '../../../components/CustomInputs';
import SnackbarContentWrapper from '../../../components/SnackbarContentWrapper';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  formContainer: {
    margin: theme.spacing(1, 0),
  },
  formControl: {
    marginBottom: 0,
    marginTop: theme.spacing(2),
  },
  helperText: {
    marginTop: 0,
  },
  textField: {},
  buttonContainer: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  bottomButtonsContainer: {
    margin: theme.spacing(1, 0),
  },
  bottomButton: {
    margin: theme.spacing(0, 1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const addInputAdornment = (adornment, position = 'end') => ({
  [`${position}Adornment`]: (
    <InputAdornment position={position}>{adornment}</InputAdornment>
  ),
});

const clearDialog = {
  isOpen: false,
  content: '',
  title: '',
  confirmationButtonText: '',
};

const ParametersPage = () => {
  const classes = useStyles();

  const user = useSelector(state => state.auth.user);

  const [confirmationType, setConfirmationType] = useState('');
  const [dateLastProcess, setDateLastProcess] = useState('');
  const [dialog, setDialog] = useState(clearDialog);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    variant: 'success',
  });

  const handleSnackbar = (
    message = '',
    variant = snackbar.variant || 'success'
  ) => {
    setSnackbar({
      isOpen: !!message,
      message,
      variant,
    });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSnackbar();
  };

  const {
    errors,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    touched,
    values,
  } = useFormik({
    initialValues: {
      cornFoxFee: '',
      soybeanFoxFee: '',
      sorghumFoxFee: '',
      moistCornFoxFee: '',
      moistSorghumFoxFee: '',
      expiresInDefault: '',
      dirtRoadKmPrice: '',
      minFreightPrice: '',
    },
    validationSchema: Yup.object({
      cornFoxFee: Yup.number()
        .positive()
        .max(100),
      soybeanFoxFee: Yup.number()
        .positive()
        .max(100),
      sorghumFoxFee: Yup.number()
        .positive()
        .max(100),
      moistCornFoxFee: Yup.number()
        .positive()
        .max(100),
      moistSorghumFoxFee: Yup.number()
        .positive()
        .max(100),
      expiresInDefault: Yup.number().positive(),
      dirtRoadKmPrice: Yup.number().positive(),
      minFreightPrice: Yup.number().positive(),
    }),
    onSubmit: async formValues => {
      try {
        const { data: res } = await axios.post('/parameters', formValues);

        if (res.status === 'OK') handleSnackbar(res.message);
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao adicionar parâmetro.',
          'error'
        );
      }
    },
  });

  const handleDialog = type => {
    const newDialog = {
      ...clearDialog,
      isOpen: !!type,
    };

    if (type === 'parameters') {
      newDialog.content = 'Tem certeza que deseja atualizar os parâmetros?';
      newDialog.title = 'Atualizar parâmetros?';
      newDialog.confirmationButtonText = 'Atualizar';
    } else if (type === 'process') {
      newDialog.content =
        'Tem certeza que deseja reprocessar as ofertas em aberto do app?';
      newDialog.title = 'Processar ofertas?';
      newDialog.confirmationButtonText = 'Processar';
    }

    setConfirmationType(type);
    setDialog(newDialog);
  };

  const handleDialogClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleDialog();
  };

  const processOffers = async () => {
    try {
      const { data: res } = await axios.patch('/offers/process');

      if (res.status === 'OK')
        handleSnackbar('Processamento de ofertas solicitado com sucesso.');
    } catch (error) {
      handleSnackbar(
        (error.response && error.response.data.message) ||
          'Erro ao solicitar processamento de ofertas. Por favor, tente mais tarde.',
        'error'
      );
    }
  };

  const handleDialogConfirmation = () => {
    setIsLoading(true);

    if (confirmationType === 'parameters') {
      handleSubmit();
    } else if (confirmationType === 'process') {
      processOffers();
    }

    setConfirmationType('');
    setDialog(clearDialog);
    setIsLoading(false);
  };

  useEffect(() => {
    const fields = Object.keys(values);

    const loadParameters = async () => {
      try {
        const { data: res } = await axios.get('/parameters/latest');

        if (res && res.status === 'OK') {
          fields.forEach(f => {
            if (!values[f]) setFieldValue(f, res.data[f]);
          });
        }
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao buscar parâmetros',
          'error'
        );
      }
    };

    if (fields.some(f => !values[f])) loadParameters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const loadDateLastProcess = async () => {
      try {
        const { data: res } = await axios.get('/offers/latest');

        if (res && res.status === 'OK' && !dateLastProcess && res.data) {
          setDateLastProcess(new Date(res.data.processedAt).toLocaleString());
        } else {
          setDateLastProcess('Nenhuma oferta processada');
        }
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao buscar a data do último processamento',
          'error'
        );
      }
    };

    if (!dateLastProcess) loadDateLastProcess();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper className={classes.root}>
      <Typography component="h3" variant="h5">
        Editar Parâmetros das Ofertas
      </Typography>
      <form className={classes.formContainer} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.cornFoxFee && !!errors.cornFoxFee}
              helperText={touched.cornFoxFee && errors.cornFoxFee}
              InputProps={{
                ...addInputAdornment('R$', 'start'),
                inputComponent: DecimalInput,
              }}
              label="Taxa de milho seco"
              margin="normal"
              name="cornFoxFee"
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue('cornFoxFee', Number(e.target.value));
              }}
              value={values.cornFoxFee}
            />
          </Grid>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.moistCornFoxFee && !!errors.moistCornFoxFee}
              helperText={touched.moistCornFoxFee && errors.moistCornFoxFee}
              InputProps={{
                ...addInputAdornment('R$', 'start'),
                inputComponent: DecimalInput,
              }}
              label="Taxa de milho úmido"
              margin="normal"
              name="moistCornFoxFee"
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue('moistCornFoxFee', Number(e.target.value));
              }}
              value={values.moistCornFoxFee}
            />
          </Grid>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.soybeanFoxFee && !!errors.soybeanFoxFee}
              helperText={touched.soybeanFoxFee && errors.soybeanFoxFee}
              InputProps={{
                ...addInputAdornment('R$', 'start'),
                inputComponent: DecimalInput,
              }}
              label="Taxa de soja"
              margin="normal"
              name="soybeanFoxFee"
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue('soybeanFoxFee', Number(e.target.value));
              }}
              value={values.soybeanFoxFee}
            />
          </Grid>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.sorghumFoxFee && !!errors.sorghumFoxFee}
              helperText={touched.sorghumFoxFee && errors.sorghumFoxFee}
              InputProps={{
                ...addInputAdornment('R$', 'start'),
                inputComponent: DecimalInput,
              }}
              label="Taxa de sorgo seco"
              margin="normal"
              name="sorghumFoxFee"
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue('sorghumFoxFee', Number(e.target.value));
              }}
              value={values.sorghumFoxFee}
            />
          </Grid>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.moistSorghumFoxFee && !!errors.moistSorghumFoxFee}
              helperText={
                touched.moistSorghumFoxFee && errors.moistSorghumFoxFee
              }
              InputProps={{
                ...addInputAdornment('R$', 'start'),
                inputComponent: DecimalInput,
              }}
              label="Taxa de sorgo úmido"
              margin="normal"
              name="moistSorghumFoxFee"
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue('moistSorghumFoxFee', Number(e.target.value));
              }}
              value={values.moistSorghumFoxFee}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.expiresInDefault && !!errors.expiresInDefault}
              helperText={touched.expiresInDefault && errors.expiresInDefault}
              InputProps={{
                ...addInputAdornment('dias'),
              }}
              label="Ofertas expiram em"
              margin="normal"
              name="expiresInDefault"
              type="number"
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue('expiresInDefault', Number(e.target.value));
              }}
              value={values.expiresInDefault}
            />
          </Grid>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.minFreightPrice && !!errors.minFreightPrice}
              helperText={touched.minFreightPrice && errors.minFreightPrice}
              InputProps={{
                ...addInputAdornment('R$', 'start'),
                inputComponent: DecimalInput,
              }}
              label="Preço mínimo do frete"
              margin="normal"
              name="minFreightPrice"
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue('minFreightPrice', Number(e.target.value));
              }}
              value={values.minFreightPrice}
            />
          </Grid>
          <Grid item md xs={12}>
            <TextField
              className={classes.textField}
              error={touched.dirtRoadKmPrice && !!errors.dirtRoadKmPrice}
              helperText={touched.dirtRoadKmPrice && errors.dirtRoadKmPrice}
              InputProps={{
                ...addInputAdornment('R$', 'start'),
                ...addInputAdornment('/ km'),
                inputComponent: DecimalInput,
              }}
              label="Preço do frete em estrada de chão"
              margin="normal"
              name="dirtRoadKmPrice"
              onBlur={handleBlur}
              onChange={e => {
                setFieldValue('dirtRoadKmPrice', Number(e.target.value));
              }}
              value={values.dirtRoadKmPrice}
            />
          </Grid>
          {user.isAdmin && (
            <Grid className={classes.buttonContainer} item md xs={12}>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={() => handleDialog('parameters')}
                size="large"
                type="button"
                variant="outlined"
              >
                Atualizar Parâmetros
              </Button>
            </Grid>
          )}
        </Grid>
        <Divider className={classes.divider} />
        <Typography component="h3" variant="h5">
          Processamento de Ofertas
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              className={classes.textField}
              disabled
              label="Último processamento de ofertas em"
              margin="normal"
              name="lastDataProcess"
              value={dateLastProcess}
            />
          </Grid>
          {user.isAdmin && (
            <Grid item md xs={12} style={{ alignSelf: 'center' }}>
              <Button
                color="primary"
                disabled={isLoading}
                onClick={() => handleDialog('process')}
                size="large"
                type="button"
                variant="outlined"
              >
                Processar Ofertas
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      <Dialog open={dialog.isOpen} onClose={handleDialogClose}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
          <DialogContentText>
            Esta ação é irreversível e todas as ofertas serão impactadas.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.bottomButtonsContainer}>
          <Button onClick={handleDialogClose} variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={handleDialogConfirmation}
            color="primary"
            autoFocus
            variant="contained"
          >
            {dialog.confirmationButtonText}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        open={snackbar.isOpen}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={snackbar.variant}
          message={snackbar.message}
        />
      </Snackbar>
    </Paper>
  );
};

export default ParametersPage;
