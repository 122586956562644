import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { pt } from 'date-fns/esm/locale';

import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  FormHelperText,
  InputAdornment,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
  TableRow,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ContractDetails from '../../../components/ContractDetails';
import CustomField from '../../../components/CustomField';
import SnackbarContentWrapper from '../../../components/SnackbarContentWrapper';

import { licensePlateMask } from '../../../../_metronic/utils/utils';

const addInputAdornment = (adornment, position = 'end') => ({
  [`${position}Adornment`]: (
    <InputAdornment position={position}>{adornment}</InputAdornment>
  ),
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: 24,
  },
  formControl: {
    marginBottom: 0,
    marginTop: 0,
    minWidth: 120,
  },
  helperText: {
    marginTop: 0,
  },
  textField: {
    marginTop: 0,
  },
  bottomButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  bottomButton: {
    margin: theme.spacing(0, 1),
  },
  title: {
    marginBottom: 16,
  },
}));

export default function TicketFormPage({ location }) {
  const classes = useStyles();
  const history = useHistory();
  const loggedUser = useSelector(state => state.auth.user);
  const [responsibleName, setResponsibleName] = useState(loggedUser.name);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    variant: 'success',
  });
  const [contractId, setContractId] = useState('');
  const [isEditingTicket, setIsEditingTicket] = useState(false);
  const [logisticsDetails, setLogisticsDetails] = useState({});
  const [trucks, setTrucks] = useState([]);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = isAutocompleteOpen && options.length === 0;
  const [truckFields, setTruckFields] = useState({
    licensePlate: '',
    tareWeight: '',
    truckCapacity: '',
  });

  const discountFields = [
    { title: 'Ardido', field: 'sour' },
    { title: 'Ardido Avariado', field: 'sourDamaged' },
    { title: 'Avariado', field: 'damaged' },
    { title: 'Carunchado', field: 'withWoodworm' },
    { title: 'Impureza', field: 'impurity' },
    { title: 'Mofado', field: 'musty' },
    { title: 'Picado', field: 'chopped' },
    { title: 'Restante do Avariado', field: 'restDamaged' },
    { title: 'Quebrado', field: 'broke' },
    { title: 'Queimado', field: 'burnt' },
    { title: 'Umidade', field: 'moisture' },
    { title: 'Verde', field: 'green' },
  ];

  const handleSnackbar = (
    message = '',
    variant = snackbar.variant || 'success'
  ) => {
    setSnackbar({
      isOpen: !!message,
      message,
      variant,
    });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSnackbar();
  };

  const {
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    setValues,
    touched,
    values,
  } = useFormik({
    initialValues: {
      deliveredIn: '',
      driverName: '',
      grossWeight: '',
      loadingDate: new Date(),
      responsible: loggedUser,
      transaction: '',
      surplus: '',
      quality: {},
      truck: '',
    },

    validationSchema: Yup.object({
      deliveredIn: Yup.string(),
      driverName: Yup.string(),
      grossWeight: Yup.number(),
      loadingDate: Yup.string().required(),
      surplus: Yup.number(),
      quality: Yup.object({
        sour: Yup.array().of(Yup.number()),
        sourDamaged: Yup.array().of(Yup.number()),
        damaged: Yup.array().of(Yup.number()),
        withWoodworm: Yup.array().of(Yup.number()),
        impurity: Yup.array().of(Yup.number()),
        musty: Yup.array().of(Yup.number()),
        chopped: Yup.array().of(Yup.number()),
        restDamaged: Yup.array().of(Yup.number()),
        broke: Yup.array().of(Yup.number()),
        burnt: Yup.array().of(Yup.number()),
        moisture: Yup.array().of(Yup.number()),
        green: Yup.array().of(Yup.number()),
      }),
      truck: Yup.string().required(),
    }),

    onSubmit: async formValues => {
      try {
        formValues.responsible = formValues.responsible._id;
        formValues.truck = formValues.truck._id;
        formValues.transaction =
          typeof formValues.transaction === 'object'
            ? formValues.transaction._id
            : formValues.transaction;

        let res;
        if (formValues._id) {
          const { data } = await axios.patch(
            `/tickets/${formValues._id}`,
            formValues
          );
          res = data;
        } else {
          const { data } = await axios.post('/tickets/', formValues);
          res = data;
        }

        if (res.status === 'OK') {
          handleSnackbar(res.message, 'success');
          setTimeout(() => {
            history.push('/tickets');
          }, 3250);
        }
      } catch (error) {
        formValues.responsible = loggedUser;
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao cadastrar ticket.',
          'error'
        );
      }
    },
  });

  useEffect(() => {
    const loadPage = async () => {
      setContractId(location.state.contractId);

      if (!location.state.editTicket) {
        setFieldValue('transaction', location.state.contractId);
      } else {
        setIsEditingTicket(true);
        const { editTicket } = location.state;
        const ticket = {
          ...editTicket,
          loadingDate: editTicket.loadingDate ? editTicket.loadingDate : '',
          responsible: editTicket.responsible
            ? editTicket.responsible
            : loggedUser,
          deliveredIn: editTicket.deliveredIn
            ? editTicket.deliveredIn
            : new Date(),
          driverName: editTicket.driverName ? editTicket.driverName : '',
          grossWeight: editTicket.grossWeight ? editTicket.grossWeight : '',
          surplus: editTicket.surplus ? editTicket.surplus : '',
          partialWeight: editTicket.partialWeight
            ? editTicket.partialWeight
            : '',
          amount: editTicket.amount ? editTicket.amount : '',
          quality: {
            sour:
              editTicket.quality.sour.length > 0
                ? editTicket.quality.sour
                : [0, 0],
            sourDamaged:
              editTicket.quality.sourDamaged.length > 0
                ? editTicket.quality.sourDamaged
                : [0, 0],
            damaged:
              editTicket.quality.damaged.length > 0
                ? editTicket.quality.damaged
                : [0, 0],
            withWoodworm:
              editTicket.quality.withWoodworm.length > 0
                ? editTicket.quality.withWoodworm
                : [0, 0],
            impurity:
              editTicket.quality.impurity.length > 0
                ? editTicket.quality.impurity
                : [0, 0],
            musty:
              editTicket.quality.musty.length > 0
                ? editTicket.quality.musty
                : [0, 0],
            chopped:
              editTicket.quality.chopped.length > 0
                ? editTicket.quality.chopped
                : [0, 0],
            restDamaged:
              editTicket.quality.restDamaged.length > 0
                ? editTicket.quality.restDamaged
                : [0, 0],
            broke:
              editTicket.quality.broke.length > 0
                ? editTicket.quality.broke
                : [0, 0],
            burnt:
              editTicket.quality.burnt.length > 0
                ? editTicket.quality.burnt
                : [0, 0],
            moisture:
              editTicket.quality.moisture.length > 0
                ? editTicket.quality.moisture
                : [0, 0],
            green:
              editTicket.quality.green.length > 0
                ? editTicket.quality.green
                : [0, 0],
          },
          truck: editTicket.truck ? editTicket.truck : '',
        };

        if (ticket.truck) {
          setTruckFields({
            licensePlate: licensePlateMask(ticket.truck.plate),
            tareWeight: ticket.truck.type.scaleWeight,
            truckCapacity: ticket.truck.type.capacities[1],
          });
        }

        let originAndSeller;
        let destinationAndBuyer;

        if (editTicket.transaction.order.isBuying) {
          destinationAndBuyer = 'order';
          originAndSeller = 'offer';
        } else {
          destinationAndBuyer = 'offer';
          originAndSeller = 'order';
        }

        setLogisticsDetails({
          originAddressNote:
            editTicket.transaction[originAndSeller].address.addressNote || '-',
          originAddressRoute:
            editTicket.transaction[originAndSeller].address.addressRoute || '-',
          originMapsLink:
            editTicket.transaction[originAndSeller].address.mapsLink || '-',
          originCity: `${editTicket.transaction[originAndSeller].address.name}, ${editTicket.transaction[originAndSeller].address.city.name} - ${editTicket.transaction[originAndSeller].address.city.uf}`,
          destinationAddressNote:
            editTicket.transaction[destinationAndBuyer].address.addressNote ||
            '-',
          destinationAddressRoute:
            editTicket.transaction[destinationAndBuyer].address.addressRoute ||
            '-',
          destinationMapsLink:
            editTicket.transaction[destinationAndBuyer].address.mapsLink || '-',
          destinationCity: `${editTicket.transaction[destinationAndBuyer].address.name}, ${editTicket.transaction[destinationAndBuyer].address.city.name} - ${editTicket.transaction[destinationAndBuyer].address.city.uf}`,
        });

        setResponsibleName(ticket.responsible.name);
        setValues(ticket, false);
      }
    };
    loadPage();
  }, [location, setFieldValue, setValues, loggedUser]);

  useEffect(() => {
    const loadTrucksTypes = async () => {
      try {
        const { data: res } = await axios.get('/trucks');
        if (res && res.status === 'OK') {
          setTrucks(res.data);
        }
      } catch (error) {
        handleSnackbar(
          (error.response && error.response.data.message) ||
            'Erro ao buscar caminhões',
          'error'
        );
      }
    };

    loadTrucksTypes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (() => {
      if (active) {
        setOptions(trucks);
      }
    })();

    return () => {
      active = false;
    };
  }, [trucks, loading]);

  useEffect(() => {
    if (!isAutocompleteOpen) setOptions([]);
  }, [isAutocompleteOpen]);

  const handleTruckFields = value => {
    setFieldValue('truck', value || '');
    if (value)
      setTruckFields({
        licensePlate: licensePlateMask(value.plate),
        tareWeight: value.type.scaleWeight,
        truckCapacity: value.type.capacities[1],
      });
  };

  const handleFormSubmition = async () => {
    const fields = Object.keys(values);
    const isFormValid = !Object.keys(errors).length;

    fields.forEach(f => setFieldTouched(f, true));

    if (isFormValid) {
      handleSubmit();
    }
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.title}>
          <Typography component="h3" variant="h5">
            {isEditingTicket ? 'Editar ticket' : 'Abrir ticket'}
          </Typography>
          <FormHelperText>Campos com * são obrigatórios.</FormHelperText>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                <DateTimePicker
                  error={touched.loadingDate && !!errors.loadingDate}
                  helperText={touched.loadingDate && errors.loadingDate}
                  className={classes.textField}
                  variant="inline"
                  format="dd/MM/yyyy HH:mm"
                  margin="normal"
                  name="loadingDate"
                  label="Data de carregamento"
                  value={values.loadingDate}
                  onChange={e => {
                    setFieldValue('loadingDate', e);
                  }}
                  onBlur={handleBlur}
                  required
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md xs={12}>
              <TextField
                className={classes.textField}
                label="Placa do caminhão"
                margin="normal"
                name="licensePlate"
                disabled
                value={truckFields.licensePlate}
              />
            </Grid>
            <Grid item md xs={12}>
              <TextField
                className={classes.textField}
                label="Peso tara"
                margin="normal"
                name="tareWeight"
                InputProps={{
                  ...addInputAdornment('kg'),
                }}
                disabled
                value={truckFields.tareWeight}
              />
            </Grid>
            <Grid item md xs={12}>
              <TextField
                className={classes.textField}
                label="Capacidade do caminhão"
                margin="normal"
                name="truckCapacity"
                InputProps={{
                  ...addInputAdornment('kg'),
                }}
                disabled
                value={truckFields.truckCapacity}
              />
            </Grid>
            <Grid item md xs={12}>
              <Autocomplete
                autoHighlight
                blurOnSelect="touch"
                filterOptions={createFilterOptions({
                  limit: 10,
                  matchFrom: 'start',
                  stringify: truck => `${truck.driver} (${truck.plate})`,
                })}
                getOptionLabel={({ driver, plate }) =>
                  driver ? `${driver} (${plate})` : ''
                }
                getOptionSelected={(truck, value) => truck._id === value._id}
                id="truck"
                loading={loading}
                onBlur={handleBlur}
                onChange={(_, value) => {
                  handleTruckFields(value);
                }}
                onClose={() => setIsAutocompleteOpen(false)}
                onOpen={() => setIsAutocompleteOpen(true)}
                open={isAutocompleteOpen}
                options={options}
                value={values.truck}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={touched.truck && !!errors.truck}
                    helperText={touched.truck && errors.truck}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    label="Motorista *"
                    value={values.truck}
                  />
                )}
              />
            </Grid>
            <Grid item md xs={12}>
              <TextField
                className={classes.textField}
                disabled
                label="Responsável"
                margin="normal"
                name="responsible"
                value={responsibleName}
              />
            </Grid>
          </Grid>
          {isEditingTicket && values.status !== 'Colheita pronta' && (
            <>
              <Grid container spacing={2}>
                <Grid item md xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                    <DateTimePicker
                      error={touched.deliveredIn && !!errors.deliveredIn}
                      helperText={touched.deliveredIn && errors.deliveredIn}
                      className={classes.textField}
                      variant="inline"
                      format="dd/MM/yyyy HH:mm"
                      margin="normal"
                      name="deliveredIn"
                      label="Data de entrega"
                      value={values.deliveredIn}
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('deliveredIn', e);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md xs={12}>
                  <TextField
                    className={classes.textField}
                    InputProps={{
                      ...addInputAdornment('kg'),
                    }}
                    label="Peso bruto"
                    margin="normal"
                    name="grossWeight"
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('grossWeight', e.target.value);
                      setFieldValue(
                        'partialWeight',
                        e.target.value - Number(truckFields.tareWeight)
                      );
                      setFieldValue(
                        'amount',
                        (e.target.value - Number(truckFields.tareWeight)) / 60
                      );
                    }}
                    type="number"
                    value={values.grossWeight}
                  />
                </Grid>
                <Grid item md xs={12}>
                  <TextField
                    className={classes.textField}
                    label="Peso parcial"
                    margin="normal"
                    name="partialWeight"
                    disabled
                    value={values.partialWeight}
                  />
                </Grid>
                <Grid item md xs={12}>
                  <TextField
                    className={classes.textField}
                    label="Quantidade de sacas"
                    margin="normal"
                    name="amount"
                    disabled
                    value={Math.round(values.amount)}
                  />
                </Grid>
                <Grid item md xs={12}>
                  <TextField
                    className={classes.textField}
                    error={touched.surplus && !!errors.surplus}
                    helperText={touched.surplus && errors.surplus}
                    label="Excedente"
                    InputProps={{
                      ...addInputAdornment('kg'),
                    }}
                    margin="normal"
                    name="surplus"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.surplus}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: 16 }}>
                <Typography component="h3" variant="h5">
                  Qualidade
                </Typography>
              </div>
              <Grid item md={6} xs={12}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Característica</TableCell>
                      <TableCell align="right">Desconto (%)</TableCell>
                      <TableCell align="right"> Desconto (kg)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {discountFields.map(value => {
                      return (
                        <TableRow key={value.field}>
                          <TableCell>{value.title}</TableCell>
                          <TableCell>
                            <TextField
                              className={classes.textField}
                              error={
                                touched[value.field] && !!errors[value.field]
                              }
                              helperText={
                                touched[value.field] && errors[value.field]
                              }
                              InputProps={{
                                ...addInputAdornment('%'),
                              }}
                              margin="normal"
                              name={value.field}
                              onBlur={handleBlur}
                              onChange={e =>
                                setFieldValue(
                                  `${`quality.${[value.field]}`}[0]`,
                                  Number(e.target.value)
                                )
                              }
                              type="number"
                              value={String(values.quality[value.field][0])}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              className={classes.textField}
                              error={
                                touched[value.field] && !!errors[value.field]
                              }
                              helperText={
                                touched[value.field] && errors[value.field]
                              }
                              InputProps={{
                                ...addInputAdornment('kg'),
                              }}
                              margin="normal"
                              name={value.field}
                              onBlur={handleBlur}
                              onChange={e =>
                                setFieldValue(
                                  `${`quality.${[value.field]}`}[1]`,
                                  Number(e.target.value)
                                )
                              }
                              type="number"
                              value={String(values.quality[value.field][1])}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </>
          )}
          <div className={classes.bottomButtonsContainer}>
            <Button
              className={classes.bottomButton}
              color="primary"
              disabled={isSubmitting}
              onClick={handleFormSubmition}
              type="button"
              variant="contained"
            >
              {isEditingTicket ? 'Salvar alterações' : 'Abrir ticket'}
            </Button>
          </div>
        </form>
        <Snackbar
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          open={snackbar.isOpen}
        >
          <SnackbarContentWrapper
            onClose={handleSnackbarClose}
            variant={snackbar.variant}
            message={snackbar.message}
          />
        </Snackbar>
      </Paper>
      {isEditingTicket && (
        <Paper style={{ padding: 24, marginBottom: 24 }}>
          <Grid container justify="space-between" spacing={2}>
            <Grid item>
              <Typography component="h3" variant="h5">
                Detalhes da logística
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography>Local de coleta</Typography>
              <a
                href={logisticsDetails.originMapsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {logisticsDetails.originCity}
              </a>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>Local de entrega</Typography>
              <a
                href={logisticsDetails.destinationMapsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {logisticsDetails.destinationCity}
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <CustomField
              field="Descrição da rota de coleta"
              value={logisticsDetails.originAddressRoute}
              xs={12}
              md={6}
            />
            <CustomField
              field="Descrição da rota de entrega"
              value={logisticsDetails.destinationAddressRoute}
              xs={12}
              md={6}
            />
          </Grid>
          <Grid container spacing={2}>
            <CustomField
              field="Observações gerais do local de coleta"
              value={logisticsDetails.originAddressNote}
              xs={12}
              md={6}
            />
            <CustomField
              field="Observações gerais do local de entrega"
              value={logisticsDetails.destinationAddressNote}
              xs={12}
              md={6}
            />
          </Grid>
        </Paper>
      )}
      {/* Negotiation */}
      <Paper style={{ padding: 24 }}>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Typography component="h3" variant="h5">
              Detalhes do contrato
            </Typography>
          </Grid>
        </Grid>
        <ContractDetails contractId={contractId} />
      </Paper>
    </>
  );
}
TicketFormPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
};

TicketFormPage.defaultProps = {
  location: {},
};
